import { createContext, ReactNode, useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useDebounceValue } from 'usehooks-ts';

// Type definitions
type FieldArrayActions = Pick<ReturnType<typeof useFieldArray>, 'fields' | 'append' | 'remove'>;

type CustomSetValue = {
  setValue: (
    name: string | undefined,
    value: any,
    options?: Parameters<ReturnType<typeof useFormContext>['setValue']>[2],
  ) => void;
};

type FormUtilities = Pick<ReturnType<typeof useFormContext>, 'register' | 'resetField'>;

type DataTableContextValue = FieldArrayActions & CustomSetValue & FormUtilities;

// Context creation
const DataTableContext = createContext<DataTableContextValue | null>(null);

const EMPTY_ARRAY: Array<any> = [];

// Custom hook for field synchronization
// We want to have a debounced version of the fields to avoid unnecessary re-renders
// But when we append we want that to be reflected in the fields immediately
// So we have a syncedFields state that is updated from debouncedFields
// We use setSyncedFields to be called when we append something so the user can see it happening right away

function useSynchronizedFields(name: string) {
  const fields = useWatch({ name });
  const [debouncedFields, setDebouncedFields] = useDebounceValue(fields, 500);
  const [syncedFields, setSyncedFields] = useState(fields);

  // Effect to update debouncedFields
  useLayoutEffect(() => {
    setDebouncedFields(fields || EMPTY_ARRAY);
  }, [fields, setDebouncedFields]);

  // Effect to update syncedFields from debouncedFields
  useLayoutEffect(() => {
    setSyncedFields(debouncedFields || EMPTY_ARRAY);
  }, [debouncedFields]);

  return [syncedFields || [], setSyncedFields];
}

// DataTableProvider component
export function DataTableProvider({ name, children }: { name: string; children: ReactNode }) {
  const {
    setValue,
    register,
    resetField,
    trigger,
    formState: { isSubmitted },
  } = useFormContext();
  const [syncedFields, setSyncedFields] = useSynchronizedFields(name);
  // Custom setValue function to handle nested fields
  const handleSetValue: CustomSetValue['setValue'] = useCallback(
    (fieldName, value, options) => {
      if (!fieldName) {
        setValue(name, value, options);
      } else {
        const nestedField = fieldName.replace(`${name}.`, '');
        setValue(`${name}.${nestedField}`, value, options);
      }
      if (isSubmitted && options?.shouldValidate !== false) trigger();
    },
    [name, setValue, trigger, isSubmitted],
  );

  /**
   * Custom append function to handle the sync between field array and the values
   * Should not be called multiple times in a row, as it will not update the values correctly (syncedFields will be outdated)
   * TODO: Fix above issue
   */
  const handleAppend: ReturnType<typeof useFieldArray>['append'] = useCallback(
    (value) => {
      if (Array.isArray(value)) {
        const newValues = [...syncedFields, ...value];
        //Update synced fields so we can see the change immediately
        setSyncedFields(newValues);

        setValue(name, newValues, { shouldDirty: true });
      } else {
        const newValues = [...syncedFields, value];

        //Update synced fields so we can see the change immediately
        setSyncedFields(newValues);

        setValue(name, newValues, { shouldDirty: true });
      }
      if (isSubmitted) trigger();
    },
    [isSubmitted, trigger, setValue, name, syncedFields, setSyncedFields],
  );

  /**
   * Custom remove function to handle the sync between field array and the values
   * Should not be called multiple times in a row, as it will not update the values correctly (syncedFields will be outdated)
   * TODO: Fix above issue
   */
  const handleRemove: ReturnType<typeof useFieldArray>['remove'] = useCallback(
    (args) => {
      if (typeof args === 'number') {
        const newValue = (syncedFields as Array<any>).toSpliced(args, 1);
        setValue(name, newValue, { shouldDirty: true });
        setSyncedFields(newValue);
      } else {
        throw new Error('DataTableContext multiple removal is not yet supported');
      }

      if (isSubmitted) trigger();
    },
    [isSubmitted, trigger, syncedFields, setValue, name, setSyncedFields],
  );

  const handleRegister: typeof register = useCallback(
    (...args) => {
      const registerResult = register(...args);
      return {
        ...registerResult,
        onChange: (e) => {
          return new Promise((resolve, reject) => {
            registerResult
              .onChange(e)
              .then(() => {
                if (isSubmitted) trigger();
                resolve();
              })
              .catch(reject);
          });
        },
      };
    },
    [register, isSubmitted, trigger],
  );

  // Memoized context value
  const contextValue = useMemo<DataTableContextValue>(
    () => ({
      append: handleAppend,
      remove: handleRemove,
      fields: syncedFields,
      setValue: handleSetValue,
      register: handleRegister,
      resetField,
    }),
    [handleAppend, handleRemove, syncedFields, handleSetValue, handleRegister, resetField],
  );

  return <DataTableContext.Provider value={contextValue}>{children}</DataTableContext.Provider>;
}

// Custom hook to use the DataTableContext
export function useDataTableContext() {
  const context = useContext(DataTableContext);

  if (!context) {
    throw new Error('useDataTableContext must be used within a DataTableProvider');
  }
  return context;
}
