import { ProcessSpecificationDTO } from 'support/types/dtos';
import { FieldDefinition } from './components/ImportModal';
import { nonNullable } from 'support/helpers/generic/generic';

type ProcessSpecField = ProcessSpecificationDTO['fields'][number];

/**
 * Transforms process specification fields into field definitions
 * @param processSpecFields - Array of process specification fields
 * @returns Array of transformed field definitions
 */
export const fromProcessSpecFieldsToFieldDefinition = ({
  processSpecFields,
  uniqueFields,
  excludedFields,
}: {
  processSpecFields: ProcessSpecificationDTO['fields'];
  uniqueFields: Array<string>;
  excludedFields: Array<string>;
}): Array<FieldDefinition> => {
  return processSpecFields
    .map((field) => transformFieldIfValid(field, processSpecFields, uniqueFields, excludedFields))
    .filter(nonNullable);
};

/**
 * Transforms a single field if it meets the validity criteria
 */
const transformFieldIfValid = (
  field: ProcessSpecField,
  allFields: Array<ProcessSpecField>,
  uniqueFields: Array<string>,
  excludedFields: Array<string>,
): FieldDefinition | undefined => {
  if (!isFieldValid(field, allFields, excludedFields)) {
    return undefined;
  }

  const isUnique = uniqueFields.includes(field.path);

  return {
    key: field.path,
    label: field.name,
    description: field.description || undefined,
    type: determineFieldType(field),
    isRequired: field.modality.type === 'MANDATORY',
    isUnique,
    options: getFieldOptions(field),
  };
};

/**
 * Checks if a field should be included in the transformation
 */
const isFieldValid = (
  field: ProcessSpecField,
  allFields: Array<ProcessSpecField>,
  excludedFields: Array<string>,
): boolean => {
  if (
    field.modality.type === 'NOT_USED' ||
    field.modality.type === 'VIRTUAL' ||
    field.is_hidden ||
    field.is_readonly ||
    field.is_virtual ||
    excludedFields.includes(field.path)
  ) {
    return false;
  }

  const hasHiddenParent = hasHiddenOrNotUsedParent(field, allFields);
  const isLeaf = isLeafField(field, allFields);

  return !hasHiddenParent && isLeaf;
};

/**
 * Checks if any parent field is hidden or not used
 */
const hasHiddenOrNotUsedParent = (field: ProcessSpecField, allFields: Array<ProcessSpecField>): boolean => {
  const parentPaths = getParentPaths(field.path);

  return parentPaths.some((parentPath) => {
    const parentField = allFields.find((f) => f.path === parentPath);
    return parentField && (parentField.is_hidden || parentField.modality.type === 'NOT_USED');
  });
};

/**
 * Gets all parent paths for a given field path
 */
const getParentPaths = (path: string): Array<string> => {
  return path
    .split('.')
    .slice(0, -1)
    .map((_, index, array) => array.slice(0, index + 1).join('.'));
};

/**
 * Checks if a field is a leaf node (has no children)
 */
const isLeafField = (field: ProcessSpecField, allFields: Array<ProcessSpecField>): boolean => {
  return !allFields.some((f) => f.path.startsWith(`${field.path}.`));
};

/**
 * Determines the field type based on the process spec field
 */
const determineFieldType = (field: ProcessSpecField): FieldDefinition['type'] => {
  const options = field.options;
  const format = options?.display?.format?.value;

  if (field.type === 'string' && format === 'select') {
    return 'enum';
  }

  if (field.type === 'number' && format === 'date') {
    return 'date';
  }

  if (field.type === 'boolean') {
    return 'boolean';
  }

  if (field.type === 'number') {
    return 'number';
  }

  return 'string';
};

/**
 * Gets the field options for enum type fields
 */
const getFieldOptions = (
  field: ProcessSpecField,
): NonNullable<ProcessSpecField['options']['display']>['options'] | undefined => {
  const options = field.options;
  return determineFieldType(field) === 'enum' ? options?.display?.options : undefined;
};

export const keepFieldsWithPrefix = (fields: Array<ProcessSpecField>, prefix: string): Array<ProcessSpecField> => {
  return fields.filter((field) => field.path.startsWith(prefix));
};

export const removeNestedFieldsWithPrefix = (
  fields: Array<ProcessSpecField>,
  prefix: string,
): Array<ProcessSpecField> => {
  return fields.filter((field) => field.path.split(prefix).length === 2);
};

export const removeFromFieldsKey = (fields: Array<FieldDefinition>, stringToRemove: string): Array<FieldDefinition> => {
  return fields.map((field) => ({
    ...field,
    key: field.key.replace(stringToRemove, ''),
  }));
};

const MODIFICATION_GROUPS__PARTIAL_ID = 'modification_groups.*';
const MODIFICATION_GROUPS_CHARGES_FIELD_PARTIAL_ID = `${MODIFICATION_GROUPS__PARTIAL_ID}.charges.*.`;
const MODIFICATION_GROUPS_ALLOWANCES_FIELD_PARTIAL_ID = `${MODIFICATION_GROUPS__PARTIAL_ID}.allowances.*.`;

const makeModificationOptionalAndOneLevel = (field: ProcessSpecField): ProcessSpecField => {
  return {
    ...field,
    modality: {
      ...field.modality,
      type: field.path.includes(MODIFICATION_GROUPS__PARTIAL_ID) ? 'OPTIONAL' : field.modality.type,
    },
    path: field.path
      .replace(
        MODIFICATION_GROUPS_CHARGES_FIELD_PARTIAL_ID,
        MODIFICATION_GROUPS_CHARGES_FIELD_PARTIAL_ID.replaceAll('*', '0'),
      )
      .replace(
        MODIFICATION_GROUPS_ALLOWANCES_FIELD_PARTIAL_ID,
        MODIFICATION_GROUPS_ALLOWANCES_FIELD_PARTIAL_ID.replaceAll('*', '0'),
      ),
  };
};

const isLeafModification = (field: ProcessSpecField): boolean => {
  if (field.path.includes(MODIFICATION_GROUPS__PARTIAL_ID)) {
    return (
      field.path.includes(MODIFICATION_GROUPS_ALLOWANCES_FIELD_PARTIAL_ID) ||
      field.path.includes(MODIFICATION_GROUPS_CHARGES_FIELD_PARTIAL_ID)
    );
  }
  return true;
};

const isPercentageFieldOnModification = (field: ProcessSpecField): boolean => {
  return field.path.includes('percentage') && field.path.includes(MODIFICATION_GROUPS__PARTIAL_ID);
};

const filterOutModification = (field: ProcessSpecField): boolean => {
  return isLeafModification(field) && !isPercentageFieldOnModification(field);
};

export const updateModificationGroups = (fields: Array<ProcessSpecField>): Array<ProcessSpecField> => {
  return fields.filter(filterOutModification).map(makeModificationOptionalAndOneLevel);
};

export const updatePackagingUnits = (fields: Array<ProcessSpecField>): Array<ProcessSpecField> => {
  return fields.map(makePackagingUnitOptionalAndOneLevel);
};

const PACKAGING_UNITS_PARTIAL_ID = 'packaging_units.*';
const makePackagingUnitOptionalAndOneLevel = (field: ProcessSpecField): ProcessSpecField => {
  return {
    ...field,
    modality: {
      ...field.modality,
      type: field.path.includes(PACKAGING_UNITS_PARTIAL_ID) ? 'OPTIONAL' : field.modality.type,
    },
    path: field.path.replace(PACKAGING_UNITS_PARTIAL_ID, PACKAGING_UNITS_PARTIAL_ID.replaceAll('*', '0')),
  };
};
