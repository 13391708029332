import { useState } from 'react';
import { ProcessSpecificationDTO, RelationshipV2 } from 'support/types';
import { SetupUploadStage } from './components/SetupUploadStage';
import { SetupConfirmationStage } from './components/SetupConfirmationStage';
import classNames from 'classnames';
import { useCreateOCRConfigurationRequest } from 'services/repositories/integrations/integrations';
import { logError } from 'services/logging/logging';
import { GenericError } from 'support/helpers/errors/errors';
import { useLocalStorage } from 'usehooks-ts';
import { SetupInfoStageProps } from './components/SetupInfoStage';
import { DocumentType } from 'support/types';

enum SetupOCRExtractorStages {
  INFO,
  UPLOAD,
  CONFIRMATION,
}

type SetupOCRExtractorProps = {
  targetRelationship: RelationshipV2;
  senderProcessSpecification: ProcessSpecificationDTO;
  setOpen: (open: boolean) => void;
  sourceDocumentId: string | undefined;
};

const BETA_EXTRACTOR_LOCAL_STORAGE_KEY_PREFIX = 'documents_uploaded_';
export const SetupOCRExtractor = ({
  setOpen,
  targetRelationship,
  senderProcessSpecification,
  sourceDocumentId,
}: SetupOCRExtractorProps) => {
  const [documentsAlreadyUploaded, setDocumentsAlreadyUploaded] = useLocalStorage(
    BETA_EXTRACTOR_LOCAL_STORAGE_KEY_PREFIX + senderProcessSpecification.process?.id,
    false,
  );
  const [stage, setStage] = useState<SetupOCRExtractorStages>(
    documentsAlreadyUploaded ? SetupOCRExtractorStages.CONFIRMATION : SetupOCRExtractorStages.INFO,
  );
  const { mutate } = useCreateOCRConfigurationRequest();

  const onUploadConfirm = () => {
    if (!targetRelationship.sender_integration?.production?.id) {
      logError(
        new GenericError('Beta Extractor: sender integration not found', {
          senderIntegration: targetRelationship.sender_integration,
        }),
      );

      return;
    }

    if (!senderProcessSpecification?.process?.id) {
      logError(
        new GenericError('Beta Extractor: sender process specification not found', { senderProcessSpecification }),
      );
      return;
    }

    mutate(
      {
        integrationId: targetRelationship.sender_integration.production.id,
        processId: senderProcessSpecification.process.id,
      },
      {
        onSuccess: () => {
          setStage(SetupOCRExtractorStages.CONFIRMATION);
          setDocumentsAlreadyUploaded(true);
        },
      },
    );
  };
  return (
    <div
      className={classNames('flex max-h-[800px] flex-col gap-4 overflow-auto p-6', {
        'h-[75vh] w-full': stage === SetupOCRExtractorStages.UPLOAD,
        'sm:max-w-xl': stage !== SetupOCRExtractorStages.UPLOAD,
      })}
    >
      {stage === SetupOCRExtractorStages.INFO && (
        <SetupInfoStageProps
          onClose={() => setOpen(false)}
          onNext={() => setStage(SetupOCRExtractorStages.UPLOAD)}
          documentType={targetRelationship.message_type as DocumentType}
        />
      )}
      {stage === SetupOCRExtractorStages.UPLOAD && (
        <SetupUploadStage
          documentType={targetRelationship.message_type as DocumentType}
          sourceDocumentId={sourceDocumentId}
          onClose={() => setOpen(false)}
          onUploadConfirm={onUploadConfirm}
        />
      )}
      {stage === SetupOCRExtractorStages.CONFIRMATION && (
        <SetupConfirmationStage
          documentType={targetRelationship.message_type as DocumentType}
          onClose={() => setOpen(false)}
          onUploadMore={() => setStage(SetupOCRExtractorStages.UPLOAD)}
        />
      )}
    </div>
  );
};
