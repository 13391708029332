export const BATCHES_SUFFIX = 'batches';

export const scrollToBatch = (tableId: string, numberOfBatches: number) => {
  const table = document.getElementById(tableId);

  if (!table) {
    return; // Return if the table is not found
  }

  const partialId = `${tableId}.0.batches.${numberOfBatches - 1}`;
  const columnElement = document.querySelector(`[id*="${partialId}"]`);
  if (!columnElement) {
    console.log('No column element found');
    return; // Return if the column element is not found
  }

  const containerRect = table.getBoundingClientRect();
  const elementRect = columnElement.getBoundingClientRect();

  // Calculate the x position of the element relative to the table
  const xPosition = elementRect.left - containerRect.left;

  // Get the current scroll position of the table
  const currentScrollLeft = table.scrollLeft;

  // Calculate the new scroll position by adding the element's x position to the current scroll position
  const newScrollLeft = currentScrollLeft + xPosition;

  // Scroll the table to the new scroll position, preserving the current vertical scroll position
  table.scrollTo({
    left: newScrollLeft,
    behavior: 'smooth',
  });
};
