import { ConnectionsFilterKey } from 'pages/Connections/tableConfig';
import { PricingConfigsFilterKey } from 'pages/PriceConfigs/tableConfig';
import { PartnerFilterKey } from 'pages/TradePartners/types';
import { TransactionFilterKey } from 'pages/Transactions/types';
import { generatePath } from 'react-router-dom';
import {
  DocumentType,
  TransactionInternalType,
  TransactionsRoutes,
  AuthRoutes,
  ConnectionsRoutes,
  PricingConfigRoutes,
  NetworkRoutes,
  PriceCatalogRoutes,
  DashboardRoutes,
} from 'support/types';

export const isPublicRoute = (pathname: string): boolean => {
  const pathStart = '/' + pathname.split('/')[1];
  return pathStart !== DashboardRoutes.root && !!Object.values(AuthRoutes).find((route) => route.startsWith(pathStart));
};

export const routeToTradePartnersPage = (filter: Partial<Record<PartnerFilterKey, string>>) => {
  return routeToPage(NetworkRoutes.root, {}, filter);
};

export const routeToProductCatalogViewPage = (type: TransactionInternalType, id: string) => {
  if (type === TransactionInternalType.message) {
    return routeToPage(PriceCatalogRoutes.viewMessage, { id });
  }
  return routeToPage(PriceCatalogRoutes.viewSenderEnvelope, { id });
};

export const routeToTransactionPage = (type: TransactionInternalType, id: string) => {
  if (type === TransactionInternalType.message) {
    return routeToPage(TransactionsRoutes.viewMessage, { id });
  }
  return routeToPage(TransactionsRoutes.viewSenderEnvelope, { id });
};

export const routeToCreatePage = (sourceMessageId: string, messageType: DocumentType, showUploadModal?: boolean) => {
  return routeToPage(
    TransactionsRoutes.create,
    { messageId: sourceMessageId, messageType },
    undefined,
    showUploadModal ? { showUpload: 'true' } : undefined,
  );
};

export const routeToStandaloneCreatePage = (relationshipId: string, messageType: DocumentType) => {
  return routeToPage(TransactionsRoutes.createStandalone, { relationshipId, messageType });
};

export const routeToPriceCatalogListPage = (relationshipId: string) => {
  return routeToPage(PriceCatalogRoutes.relationshipDetails, { relationshipId });
};

export const routeToPricingConfigPage = (filter: Partial<Record<PricingConfigsFilterKey, string>>) => {
  return routeToPage(PricingConfigRoutes.root, {}, filter);
};

export const routeToPricingConfigEditPage = (variables?: { partnerId: string; id: string }) => {
  if (!variables?.partnerId || !variables.id) {
    return PricingConfigRoutes.new;
  }

  return routeToPage(PricingConfigRoutes.view, variables);
};

export const routeToTransactionsPage = (filter: Partial<Record<TransactionFilterKey, string>>) => {
  return routeToPage(TransactionsRoutes.root, {}, filter);
};

export const routeToConnectionsPage = (filter: Partial<Record<ConnectionsFilterKey, string>>) => {
  return routeToPage(ConnectionsRoutes.root, {}, filter);
};

const routeWithFilters = (pathname: string, filters: Record<string, string>): string => {
  const params = new URLSearchParams();

  if (Object.keys(filters).length === 0) {
    return pathname;
  }

  Object.entries(filters).forEach(([key, value]) => {
    params.append(`filter[${key}]`, value);
  });

  return `${pathname}?${params.toString()}`;
};

export const routeToPage = <Path extends string>(
  pathname: Path,
  params?: Parameters<typeof generatePath<Path>>[1],
  filters?: Record<string, string>,
  searchParams?: Record<string, string>,
): string => {
  return (
    routeWithFilters(generatePath(pathname, params), filters ?? {}) +
    (searchParams
      ? `${filters && Object.keys(filters).length ? '&' : '?'}${new URLSearchParams(searchParams).toString()}`
      : '')
  );
};
