import { DialogTitle, useClose } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/Form/Button';
import { Translate } from 'components/Translate/Translate';
import { useTranslation } from 'react-i18next';
import { DocumentType } from 'support/types';

type SetupConfirmationStageProps = {
  onClose: () => void;
  onUploadMore: () => void;
  documentType: DocumentType | undefined;
};

export const SetupConfirmationStage = ({ onClose, onUploadMore, documentType }: SetupConfirmationStageProps) => {
  const { t } = useTranslation();
  const close = useClose();

  return (
    <>
      <div className="flex items-start justify-between">
        <div>
          <DialogTitle as="h3" className="text-lg font-medium text-gray-900">
            {t('webedi:documentExtractor.beta.confirm.title')}
          </DialogTitle>
        </div>
        <Button
          variant="minimal"
          iconOnly
          size="extra-small"
          LeftIcon={XMarkIcon}
          onClick={close}
          analyticsId="document-extractor:close"
        />
      </div>
      <p className="text-sm text-gray-700">
        <Translate
          i18nKey="webedi:documentExtractor.beta.confirm.description"
          values={{ documentType: t(`common:messageTypes.plural.${documentType}`) }}
        />
      </p>
      <div className="flex justify-end gap-2">
        <Button
          type="button"
          variant="secondary"
          onClick={onUploadMore}
          analyticsId="document-extractor-beta:upload-more"
        >
          {t('webedi:documentExtractor.beta.confirm.actions.uploadMore')}
        </Button>
        <Button type="button" onClick={onClose} analyticsId="document-extractor-beta:close">
          {t('common:close')}
        </Button>
      </div>
    </>
  );
};
