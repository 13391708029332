import { useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { setIsValidating, useWebEDIStore } from 'stores/webedi/webedi';
import { DATA_CHECK_ERROR_CODE, EMPTY_FUNCTION } from 'support/helpers/const/const';
import { datachecksResolver } from 'support/helpers/resolvers/datachecksResolver';
import { MessageV2DTO, ProcessSpecificationDTO } from 'support/types';

type UseMessageViewFormArgs = {
  message: MessageV2DTO | undefined;
  flattenData: Record<string, any>;
  processSpecFields: ProcessSpecificationDTO['fields'] | undefined;
};

export const useMessageViewForm = ({ message, flattenData, processSpecFields }: UseMessageViewFormArgs) => {
  const isValidating = useWebEDIStore((state) => state.isValidating);
  const hasDatachecksError = message?.blocking_error?.code === DATA_CHECK_ERROR_CODE;
  const resolver = useMemo(
    () => (hasDatachecksError ? datachecksResolver(processSpecFields) : undefined),
    [processSpecFields, hasDatachecksError],
  );
  const isValidatingDataChecks = hasDatachecksError && isValidating;

  const formMethods = useForm<typeof flattenData>({
    values: flattenData,
    resolver,
  });

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (flattenData) {
      setIsInitialized(true);
    }
  }, [flattenData]);

  useEffect(() => {
    setIsInitialized(false);
  }, [message]);

  useEffect(() => {
    if (hasDatachecksError && flattenData) {
      // Validate data right away if there's a datachecks error
      // Without triggering validation, react-hook-forms won't give us the errors
      setIsValidating(true);
      formMethods.handleSubmit(EMPTY_FUNCTION)();
    }
  }, [flattenData, formMethods, hasDatachecksError]);

  const isLoading = isValidatingDataChecks || !flattenData || !isInitialized;

  return { formMethods, isLoading };
};
