import { PropertyFilter, PropertyFilterType } from 'components/ListTable/types';
import { useNewFilters } from 'hooks/useFilters';
import { t } from 'i18next';
import { useState } from 'react';
import { DocumentType } from 'support/types';

export enum PriceCatalogsFilterKeys {
  messageType = 'message_type',
  partnerName = 'partner_name',
}
const getDefaultFilterConfig = (): Array<PropertyFilter> => [
  {
    key: PriceCatalogsFilterKeys.partnerName,
    type: PropertyFilterType.search,
    placeholder: t('priceCatalogs:partnerList.filters.search.label'),
    value: '',
  },
  {
    key: PriceCatalogsFilterKeys.messageType,
    type: PropertyFilterType.hidden,
    value: DocumentType.productCatalog,
  },
];

export const usePriceCatalogsFilters = () => {
  const [defaultConfig] = useState(() => getDefaultFilterConfig());
  const { filterValues, filters, updateFilters, clearAllFilters } = useNewFilters(defaultConfig, 'price-catalogs');

  return {
    filterValues,
    filters,
    updateFilters,
    clearAllFilters,
  };
};
