import { BookOpenIcon } from '@heroicons/react/24/outline';
import { InfiniteData } from '@tanstack/react-query';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { Page } from 'components/Page/Page';
import { TransactionFilterKey } from 'pages/Transactions/types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { ConnectionsResponseDTO, useInfiniteConnections } from 'services/repositories/relationships/relationships';
import { useInfiniteTransactions } from 'services/repositories/transactions/transactions';
import {
  Connectors,
  DocumentType,
  NetworkRoutes,
  PartnerV2,
  PriceCatalogRoutes,
  RelationshipStatus,
  TransactionDTO,
  TransactionFlowState,
} from 'support/types';
import { CatalogPartnerDetails } from './CatalogPartnerDetails/CatalogPartnerDetails';
import { CatalogPartnerList } from './CatalogPartnerList/CatalogPartnerList';
import { PriceCatalogsFilterKeys, usePriceCatalogsFilters } from './filters';
import { Filters } from 'components/ListTable/Filters/Filters';

export type CatalogPartnerListItem = {
  partner: PartnerV2;
  partnerIsSender: boolean;
  catalogRelationshipId: string;
  senderUsesWebEDI: boolean;
  messageCount: number;
  lastMessageCreatedAt?: string;
};

const buildCatalogPartnerList = (
  currentPartnerId: string,
  connectionsData: InfiniteData<ConnectionsResponseDTO>,
  transactionsData: Array<TransactionDTO>,
): Array<CatalogPartnerListItem> => {
  return connectionsData.pages
    .flatMap((page) => page.data)
    .reduce((acc, partner) => {
      const catalogRelationship = [
        ...(partner.sender_relationships ?? []),
        ...(partner.receiver_relationships ?? []),
      ].find(
        (relationship) =>
          relationship.message_type === DocumentType.productCatalog &&
          (relationship.sender_partner.id === currentPartnerId ||
            relationship.receiver_partner?.id === currentPartnerId) &&
          [RelationshipStatus.ACTIVE, RelationshipStatus.LIVE].includes(RelationshipStatus[relationship.status]),
      );

      if (!catalogRelationship) {
        return acc;
      }

      const catalogMessages = transactionsData
        .filter(
          (transaction) =>
            (transaction.receiver?.id === partner.id || transaction.sender?.id === partner.id) &&
            transaction.documentType === DocumentType.productCatalog,
        )
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      return [
        ...acc,
        {
          partner,
          partnerIsSender: catalogRelationship.sender_partner.id === currentPartnerId,
          senderUsesWebEDI: catalogRelationship.sender_integration?.production?.connector === Connectors.WEB_EDI,
          catalogRelationshipId: catalogRelationship.id,
          messageCount: catalogMessages.length,
          lastMessageCreatedAt: catalogMessages.length > 0 ? catalogMessages[0].createdAt : undefined,
        },
      ];
    }, [] as Array<CatalogPartnerListItem>);
};

export function PriceCatalogsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { relationshipId } = useParams();
  const [catalogPartnerDetailsSlideOpen, setCatalogPartnerDetailsSlideOpen] = useState(Boolean(relationshipId));

  useEffect(() => {
    if (relationshipId) {
      setCatalogPartnerDetailsSlideOpen(true);
    }
  }, [relationshipId]);

  const { data: currentPartner, isLoading: isLoadingCurrentPartner } = useCurrentPartner();

  // TODO: We need to find a better solution for this
  // Either we include (some) messages in the connections response
  // Or we make a separate call to get the messages from each list item (bad n+1)
  // Or we simply don't show the message count in the list

  // Also, using isFetching here so the loading shows also when stale data is refetched
  // after a catalog is created
  const { data: transactionsData, isFetching: transactionsDataLoading } = useInfiniteTransactions({
    variables: {
      query: {
        filterValues: [
          { key: TransactionFilterKey.transactionType, value: DocumentType.productCatalog },
          {
            key: TransactionFilterKey.transactionFlow,
            value: TransactionFlowState.live,
          },
        ],
        perPage: '100',
      },
    },
  });

  const { filterValues, updateFilters, clearAllFilters, filters } = usePriceCatalogsFilters();
  const { data: connectionsData, isLoading: isLoadingConnections } = useInfiniteConnections({
    variables: {
      query: {
        filterValues,
        perPage: '100',
        include: 'senderRelationships.notificationLogs,receiverRelationships.notificationLogs',
      },
    },
  });

  const hasSearchFilterApplied = filterValues.some((filter) => filter.key === PriceCatalogsFilterKeys.partnerName);

  const catalogPartners = useMemo(() => {
    if (!currentPartner || !connectionsData || !transactionsData) {
      return [];
    }

    return buildCatalogPartnerList(
      currentPartner.id,
      connectionsData,
      transactionsData.pages.flatMap((page) => page.items),
    );
  }, [currentPartner, connectionsData, transactionsData]);

  const isLoading = isLoadingCurrentPartner || transactionsDataLoading || isLoadingConnections;

  return (
    <Page>
      <Page.Head title={t('common:mainMenu.priceCatalogs')} description={t('priceCatalogs:partnerList.subtitle')}>
        <Filters
          propertyFilters={filters}
          onFiltersChange={updateFilters}
          filterValues={filterValues}
          clearAllFilters={clearAllFilters}
        />
      </Page.Head>

      <Page.Section>
        {isLoading || !!catalogPartners.length || hasSearchFilterApplied ? (
          <CatalogPartnerList data={catalogPartners} isLoading={isLoading} onClearFilters={clearAllFilters} />
        ) : (
          <EmptyState
            title={t('priceCatalogs:partnerList.emptyState.title')}
            description={t('priceCatalogs:partnerList.emptyState.description')}
            ctaText={t('priceCatalogs:partnerList.emptyState.cta')}
            ctaOnClick={() => navigate(NetworkRoutes.root)}
            icon={<BookOpenIcon />}
          />
        )}
      </Page.Section>
      <CatalogPartnerDetails
        open={catalogPartnerDetailsSlideOpen}
        partnerIsSender={
          catalogPartners.find((partner) => partner.catalogRelationshipId === relationshipId)?.partnerIsSender ?? false
        }
        senderUsesWebEDI={
          catalogPartners.find((partner) => partner.catalogRelationshipId === relationshipId)?.senderUsesWebEDI ?? false
        }
        onClose={() => {
          setCatalogPartnerDetailsSlideOpen(false);
          navigate(PriceCatalogRoutes.root);
        }}
        relationshipId={relationshipId}
      />
    </Page>
  );
}
