import { useClose, DialogTitle, Description } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useIsMutating } from '@tanstack/react-query';
import { Alert, AlertSection } from 'components/Display/Alert';
import { Button } from 'components/Form/Button';
import { FileInput } from 'components/Form/FileInput/FileInput';
import { UploadLoader } from 'components/Form/FileInput/Loaders/UploadLoader/UploadLoader';
import { Translate } from 'components/Translate/Translate';
import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ENV } from 'services/environment/environment';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { useAnalytics } from 'support/helpers/analytics/analytics';
import { DocumentType } from 'support/types';

type SetupUploadStageProps = {
  documentType: DocumentType;
  onClose: () => void;
  onUploadConfirm: () => void;
  sourceDocumentId: string | undefined;
};

type UploadError = {
  title: string;
  message: string;
};

export const SetupUploadStage = ({
  documentType,
  onClose,
  onUploadConfirm,
  sourceDocumentId,
}: SetupUploadStageProps) => {
  const { track } = useAnalytics();
  const { t } = useTranslation();
  const close = useClose();
  const [loadedFilesCounter, setLoadedFilesCounter] = useState(0);
  const [uploadError, setUploadError] = useState<UploadError>();
  const { data: currentPatner } = useCurrentPartner();
  const loader = useMemo(() => {
    track('webedi:document_upload_beta_started');
    return UploadLoader({
      url: `${ENV.DOCUPARSE_API_URL}api/v1/upload`,
      bearerToken: ENV.DOCUPARSE_API_KEY,
      body: {
        sender_partner_id: currentPatner?.id,
        document_type: documentType,
        source_document_id: sourceDocumentId,
      },
    });
  }, [currentPatner, documentType, sourceDocumentId, track]);

  const onError = useCallback(
    (error: any) => {
      setUploadError({
        title: t('webedi:documentExtractor.beta.upload.error.title'),
        message: error.message,
      });
      console.error(error);
    },
    [t],
  );

  const onLoad = useCallback(
    (_: any, filename: string) => {
      setLoadedFilesCounter((counter) => counter + 1);
      track('webedi:document_upload_beta_completed', { filename });
    },
    [track],
  );

  const isMutating = useIsMutating();

  return (
    <>
      <div className="mb-4 flex items-start justify-between">
        <div>
          <DialogTitle as="h3" className="flex items-center text-lg font-medium text-gray-900">
            {t('webedi:documentExtractor.beta.upload.title', {
              documentType: t(`common:messageTypes.plural.${documentType}`),
            })}
          </DialogTitle>
          <Description className="mt-2 text-sm leading-tight text-gray-500">
            <Translate i18nKey="webedi:documentExtractor.beta.upload.subTitle" />
          </Description>
        </div>
        <Button
          variant="minimal"
          iconOnly
          size="extra-small"
          LeftIcon={XMarkIcon}
          onClick={close}
          analyticsId="document-extractor-beta:close"
        />
      </div>
      <FileInput
        loadingLabel={<Translate i18nKey="webedi:documentExtractor.beta.upload.loadingLabel" />}
        accept={{
          'application/pdf': ['.pdf'],
          'text/csv': ['.csv'],
          'application/vnd.ms-excel': ['.xls', '.xlsx'],
        }}
        onError={onError}
        onClear={() => setLoadedFilesCounter((counter) => counter - 1)}
        onLoad={onLoad}
        loader={loader}
        dropzoneClassname="h-full"
        multiple
      />
      {uploadError ? (
        <div className="mt-3 sm:mt-4">
          <Alert severity="error" fullWidth withoutIcon className="rounded-md">
            <AlertSection severity="error" title={uploadError?.title ?? ''}>
              {uploadError?.message}
            </AlertSection>
          </Alert>
        </div>
      ) : null}
      <div className="flex justify-end gap-2">
        <Button type="button" variant="secondary" onClick={onClose} analyticsId="document-extractor-beta:cancel-upload">
          {t('common:cancel')}
        </Button>
        <Button
          type="button"
          onClick={onUploadConfirm}
          analyticsId="document-extractor-beta:confirm-upoad"
          disabled={loadedFilesCounter <= 0}
          loading={Boolean(isMutating)}
        >
          {t('webedi:documentExtractor.beta.upload.actions.submit')}
        </Button>
      </div>
    </>
  );
};
