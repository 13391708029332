import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { InProgresCircle } from 'components/CustomIcons/InProgressCircle';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMessageWithProcessSpecification } from 'services/repositories/messages/messages';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { classNames } from 'support/helpers/generic/generic';
import { routeToTransactionPage } from 'support/helpers/navigation/navigation';
import { translationForDocumentType } from 'support/helpers/transactions/transactions';
import {
  DocumentType,
  MessageDTO,
  PartnerDTO,
  TransactionInternalType,
  TransactionState,
  ValueOf,
} from 'support/types';

type RelatedMessageType = {
  id: string;
  type?: ValueOf<DocumentType> | null;
  externalMessageRef?: string | null;
  createdAt: string;
  sender?: PartnerDTO;
  receiver?: PartnerDTO;
  state: MessageDTO['state'];
};

type RelatedMessageProps = {
  relatedMessage: Pick<RelatedMessageType, 'id' | 'externalMessageRef' | 'type' | 'createdAt' | 'state'>;
  messageId: string;
  displayBorder: boolean;
  collapsed?: boolean;
};
type IconForMessageProps = Pick<RelatedMessageProps['relatedMessage'], 'state'> & { active: boolean };
const IconForMessage = forwardRef<HTMLDivElement, IconForMessageProps>(({ state, active }, ref) => {
  let icon = null;
  let classes;
  switch (state) {
    case TransactionState.success:
      icon = <CheckCircleIcon className="m-2 size-5" data-testid="CheckCircleIcon" />;
      classes = active
        ? 'bg-procuros-green-50 text-procuros-green-600 border-procuros-green-200'
        : 'group-hover:text-procuros-green-500 group-hover:bg-procuros-green-50';
      break;
    case TransactionState.pending:
      icon = <InProgresCircle className="m-2 size-5" data-testid="InProgresCircle" />;
      classes = active
        ? 'bg-yellow-50 text-yellow-600 border-yellow-200'
        : 'group-hover:text-yellow-500 group-hover:bg-yellow-50';
      break;

    case TransactionState.failed:
      icon = <ExclamationCircleIcon className="m-2 size-5" data-testid="ExclamationCircleIcon" />;
      classes = active ? 'bg-red-50 text-red-600 border-red-200' : 'group-hover:text-red-500 group-hover:bg-red-50';
      break;

    case TransactionState.dropped:
    case TransactionState.unknown:
      icon = <XCircleIcon className="m-2 size-5" data-testid="XCircleIcon" />;
      classes = active
        ? 'bg-gray-50 text-gray-600 border-gray-200'
        : 'group-hover:text-gray-500 group-hover:bg-gray-50';
      break;
  }

  return (
    <span
      className={classNames(
        'inline-block relative rounded-lg border',
        active ? undefined : 'text-gray-400 bg-gray-50 border-transparent',
        classes,
      )}
      ref={ref}
    >
      {icon}
    </span>
  );
});

IconForMessage.displayName = 'IconForMessage';

export function RelatedMessage({ relatedMessage, messageId, displayBorder, collapsed }: RelatedMessageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const active = relatedMessage.id === messageId;

  // Prefetch the message with process specification
  useMessageWithProcessSpecification({ variables: { messageId: relatedMessage.id } });

  return (
    <li key={relatedMessage.id}>
      <div
        className={classNames('w-full flex items-center group h-14', active ? 'cursor-default' : 'cursor-pointer')}
        data-testid={`LinkToTransaction-${relatedMessage.id}`}
        onClick={() => {
          navigate(routeToTransactionPage(TransactionInternalType.message, relatedMessage.id));
        }}
      >
        <Tooltip placement="left" enabled={Boolean(collapsed)}>
          <TooltipTrigger>
            <IconForMessage state={relatedMessage.state} active={active} />
          </TooltipTrigger>
          <TooltipContent>
            {translationForDocumentType(t, relatedMessage.type)}: {relatedMessage.externalMessageRef}
          </TooltipContent>
        </Tooltip>

        {collapsed ? null : (
          <div className="ml-4 w-full">
            <span className="flex flex-col justify-between align-middle">
              <span
                className={classNames(
                  'text-sm leading-5',
                  active
                    ? 'text-black font-semibold'
                    : 'text-gray-500 group-hover:text-gray-900 group-hover:font-medium',
                )}
              >
                {translationForDocumentType(t, relatedMessage.type)}
              </span>
              <span className={classNames('text-xs font-normal', 'text-gray-500 group-hover:text-gray-700')}>
                {relatedMessage.externalMessageRef}
              </span>
              <span className={classNames('text-xs font-normal', 'text-gray-500 group-hover:text-gray-700')}>
                {formatDayAndTime(relatedMessage.createdAt)}
              </span>
            </span>
          </div>
        )}
      </div>
      {displayBorder && <div className={classNames('border-r border-gray-300 w-5 h-5')} />}
    </li>
  );
}
