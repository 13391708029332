import { DocumentType } from 'support/types';

export type ChartDetails = {
  name: string;
  data: Array<TransactionData>;
};

export type TransactionData = {
  date: string;
  count: number;
};

export type TransactionSeries = {
  name: string;
  data: Array<Array<number>>;
};

export type TransactionGraphData = {
  orders: Array<TransactionData>;
  shippingNotices: Array<TransactionData>;
  invoices: Array<TransactionData>;
};

export type TradePartnerStats = {
  ready: number;
  inTesting: number;
};

export type TransactionVolumeStats = {
  name: string;
  count: number;
  previousCount: number;
  change?: number;
  changeType?: string;
  type: DocumentType | undefined;
};

export type VolumeStats = {
  allTransactions: TransactionVolumeStats;
  orders: TransactionVolumeStats;
  shippingNotices: TransactionVolumeStats;
  invoices: TransactionVolumeStats;
};

export type DateRange = {
  label: string;
  value: number;
  interval: number;
  testId?: string;
};

export enum StatChangeType {
  level = 'level',
  increase = 'increase',
  decrease = 'decrease',
}
