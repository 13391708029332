import { InfiniteScroll } from 'components/ListTable/InfiniteScroll/InfiniteScroll';
import { ListTable } from 'components/ListTable/ListTable';
import { PropertyFilter, PropertyFilterType, TableHeader } from 'components/ListTable/types';
import { Page } from 'components/Page/Page';
import { TransactionStatusDisplay } from 'components/TransactionStatusDisplay/TransactionStatusDisplay';
import { useNewFilters } from 'hooks/useFilters';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useInfiniteInboxMessages } from 'services/repositories/inbox/inbox';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { routeToTransactionPage } from 'support/helpers/navigation/navigation';
import { translationForDocumentType } from 'support/helpers/transactions/transactions';
import { DashboardRoutes, MessageDTO, TransactionInternalType } from 'support/types';

const supportedTypes = ['pendingOrders', 'pendingOrderResponses', 'pendingShippingNotices', 'pendingInvoices'];
const filters: Array<PropertyFilter> = [
  {
    key: 'type',
    type: PropertyFilterType.search,
    value: supportedTypes[0],
  },
];

export function Inbox() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: currentPartner } = useCurrentPartner();
  const { filterValues, perPage } = useNewFilters(filters, 'inbox');
  const activeType = filterValues.find((filter) => filter.key === 'type')?.value;

  const {
    data: messagesData,
    isLoading: inboxMessagesLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasPreviousPage,
    hasNextPage,
  } = useInfiniteInboxMessages({
    variables: {
      query: {
        filterValues,
        perPage,
      },
    },
    staleTime: 0, //make sure to override our default options
    refetchOnMount: true, //make sure to override our default options
  });

  useEffect(() => {
    if (!activeType || !supportedTypes.includes(activeType)) {
      navigate(DashboardRoutes.root);
    }
  }, [activeType, navigate]);

  const tableHeaders: Array<TableHeader> = [
    { label: t('transactions:list.tableHeaders.externalReference') },
    { label: t('transactions:list.tableHeaders.type') },
    { label: t('transactions:list.tableHeaders.tradePartner') },
    { label: t('transactions:list.tableHeaders.status') },
    { label: t('transactions:list.tableHeaders.date') },
  ];

  const buildRow = (message: MessageDTO, index: number) => {
    const partner = currentPartner?.id !== message.sender?.id ? message.sender : message.receiver;
    const typeLocale = translationForDocumentType(t, message.type);

    return [
      {
        element: (
          <div className="text-sm text-gray-900">
            {message.externalMessageRef?.length ? message.externalMessageRef : '–'}
          </div>
        ),
        key: `external-ref ${message.id ?? ''} ${index}`,
      },
      {
        element: <div className="text-sm text-gray-900">{message.type?.length ? typeLocale : '–'}</div>,
        key: 'type',
      },
      {
        element: <div className="text-sm text-gray-900">{partner?.name ?? '–'}</div>,
        key: 'partner-name',
      },
      {
        element: <TransactionStatusDisplay state={message.state} />,
        key: 'message-status',
      },
      {
        element: <div className="text-sm text-gray-900">{formatDayAndTime(message.createdAt)}</div>,
        key: 'created-at',
      },
    ];
  };

  // t('inbox:overview.pendingOrders')
  // t('inbox:overview.pendingOrderResponses')
  // t('inbox:overview.pendingShippingNotices')
  // t('inbox:overview.pendingInvoices')
  return (
    <Page>
      <Page.Head title={t(`inbox:overview.${activeType}`)} />
      <Page.Section>
        <ListTable<MessageDTO>
          headers={tableHeaders}
          data={messagesData?.pages?.flatMap((page) => page.items) ?? []}
          isLoading={inboxMessagesLoading}
          rowBuilder={buildRow}
          rowClickPath={(message) => routeToTransactionPage(TransactionInternalType.message, message.id)}
          pagination={
            <InfiniteScroll
              hasMore={Boolean(hasNextPage)}
              loadMore={fetchNextPage}
              loading={isFetchingNextPage}
              noMoreItemsEnabled={Boolean(hasPreviousPage)}
            />
          }
        />
      </Page.Section>
    </Page>
  );
}
