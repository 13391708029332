import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import { Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { useCurrentUser, useSignOut } from 'services/repositories/user/user';
import { classNames } from 'support/helpers/generic/generic';
import { AuthRoutes } from 'support/types';

const getInitials = (name: string): string => {
  const [firstName, lastName] = name.split(' ');

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  }

  return firstName.charAt(0).toUpperCase();
};

type SidebarProfileType = {
  value: string;
  onClick?: () => void;
  icon?: ReactElement;
  href?: string;
};

export function SidebarProfile({ open }: { open: boolean }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: currentUser } = useCurrentUser();
  const { data: currentPartner } = useCurrentPartner();
  const { mutate: logout } = useSignOut();

  const dropDownItems: Array<SidebarProfileType> = [
    {
      value: 'Logout',
      icon: <ArrowLeftOnRectangleIcon />,
      onClick: () =>
        logout(null, {
          onSuccess: () => {
            navigate(AuthRoutes.login, { replace: true });
          },
        }),
    },
  ];

  if (!currentUser || !currentPartner) {
    return null;
  }
  const partnerName = currentPartner.name;
  const userName = currentUser.name;
  const initials = getInitials(userName);

  return (
    <div className="relative inline-flex h-[54px] w-full items-center justify-center border-t border-gray-200 bg-transparent text-gray-600 hover:bg-gray-200 hover:text-gray-900 focus-visible:outline-none">
      <Menu as="div" className="absolute z-[2000] flex w-full text-left">
        <Menu.Button className="flex w-full items-center focus-visible:outline-none">
          <div
            className={classNames(
              'group px-3.5 py-2 text-left text-sm font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-100',
              open ? ' w-5/6' : '',
            )}
          >
            <span className="flex w-full items-center justify-between">
              <div className="flex w-full min-w-0 items-center justify-between space-x-3">
                <span className="inline-flex size-10 items-center justify-center rounded-full bg-gray-200">
                  <span className="text-sm font-medium leading-none text-gray-700">{initials}</span>
                </span>
                {open ? (
                  <span className="flex min-w-0 flex-1 flex-col overflow-hidden">
                    <span className="truncate text-sm font-medium text-gray-700">{userName}</span>
                    <span className="truncate text-sm text-gray-600">{partnerName}</span>
                  </span>
                ) : null}
              </div>
            </span>
          </div>
          {open ? (
            <div className="w-1/6">
              <span className="sr-only">{t('common:openOptions')}</span>
              <EllipsisVerticalIcon className="size-6" aria-hidden="true" />
            </div>
          ) : null}
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classNames(
              'absolute text-centerleft-0 bottom-0 z-[500] w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 cursor-pointer focus-visible:outline-none',
              open ? 'ml-60' : 'ml-16',
            )}
          >
            <div className="py-1">
              {dropDownItems.map(({ value, onClick, icon, href }: SidebarProfileType) => (
                <Menu.Item key={value}>
                  {({ active }) => (
                    <>
                      <a className="flex w-full flex-row items-center hover:bg-gray-200" href={href} onClick={onClick}>
                        <div className="ml-4 size-6 text-gray-600">{icon}</div>
                        <div className="pl-2">
                          <div
                            className={classNames(
                              active ? ' text-gray-900' : 'text-gray-700',
                              'ml-4 block px-4 py-2 text-sm',
                            )}
                          >
                            {value}
                          </div>
                        </div>
                      </a>
                    </>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
