import { Alert } from 'components/Display/Alert/Alert';
import { SlideOver, SlideOverTitleSkeleton } from 'components/Display/SlideOver/SlideOver';
import { ListTable } from 'components/ListTable/ListTable';
import { PartnerLogo } from 'components/PartnerLogo/PartnerLogo';
import { TransactionStatusDisplay } from 'components/TransactionStatusDisplay/TransactionStatusDisplay';
import { TransactionFilterKey } from 'pages/Transactions/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRelationship } from 'services/repositories/relationships/relationships';
import { useInfiniteTransactions } from 'services/repositories/transactions/transactions';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { useTruthyCachedValue } from 'support/helpers/hooks/useTruthyCachedValue';
import { routeToPage } from 'support/helpers/navigation/navigation';
import { DocumentType, PriceCatalogRoutes, TransactionDTO, TransactionFlowState } from 'support/types';

export const CatalogPartnerDetails = ({
  onClose,
  open,
  partnerIsSender,
  senderUsesWebEDI,
  relationshipId,
}: {
  onClose: () => void;
  open: boolean;
  partnerIsSender: boolean;
  senderUsesWebEDI: boolean;
  relationshipId: string | undefined;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const relationshipIdInView = useTruthyCachedValue(open, relationshipId);

  const { data: catalogRelationship, isLoading: isLoadingCatalogRelationship } = useRelationship({
    variables: {
      relationshipId: relationshipIdInView,
    },
    enabled: Boolean(open && relationshipIdInView),
  });

  const otherPartner = useMemo(() => {
    if (!catalogRelationship) {
      return null;
    }

    return partnerIsSender ? catalogRelationship.receiver_partner : catalogRelationship.sender_partner;
  }, [catalogRelationship, partnerIsSender]);

  const { data: transactionsData, isFetching: transactionsDataLoading } = useInfiniteTransactions({
    variables: {
      query: {
        filterValues: [
          { key: TransactionFilterKey.partnerId, value: otherPartner?.id ?? '' },
          { key: TransactionFilterKey.transactionType, value: DocumentType.productCatalog },
          {
            key: TransactionFilterKey.transactionFlow,
            value: TransactionFlowState.live,
          },
        ],
        perPage: '100',
      },
    },
    enabled: Boolean(open && otherPartner),
  });

  const tableHeaders = [
    { key: 'name', label: t('priceCatalogs:catalogList.tableHeaders.name') },
    { key: 'status', label: t('priceCatalogs:catalogList.tableHeaders.status') },
    { key: 'date', label: t('priceCatalogs:catalogList.tableHeaders.date') },
  ];

  const buildRow = (catalogTransaction: TransactionDTO) => {
    return [
      { key: `name-${catalogTransaction.id}`, element: catalogTransaction.externalMessageRef },
      {
        key: `status-${catalogTransaction.id}`,
        element: <TransactionStatusDisplay state={catalogTransaction.state} />,
      },
      {
        key: `date-${catalogTransaction.id}`,
        element: <span className="tabular-nums">{formatDayAndTime(catalogTransaction.createdAt)}</span>,
      },
    ];
  };

  return (
    <SlideOver
      size="large"
      open={open}
      setOpen={onClose}
      title={otherPartner?.name || <SlideOverTitleSkeleton />}
      subtitle={t('priceCatalogs:catalogList.subtitle')}
      headerImage={<PartnerLogo imageUrl={otherPartner?.logo_url} size="large" isLoading={!otherPartner} />}
      actionConfig={
        relationshipIdInView && partnerIsSender && senderUsesWebEDI
          ? {
              children: t('priceCatalogs:catalogList.actions.newCatalog'),
              onClick: () =>
                navigate(
                  routeToPage(PriceCatalogRoutes.create, {
                    relationshipId: relationshipIdInView,
                    messageType: DocumentType.productCatalog,
                  }),
                ),
              analyticsId: 'price_catalogs:catalog_create_navigate',
            }
          : undefined
      }
    >
      <>
        {partnerIsSender && !senderUsesWebEDI && (
          <Alert severity="neutral">{t('priceCatalogs:catalogList.emptyState.nonWebEDINotice')}</Alert>
        )}
        <ListTable<TransactionDTO>
          headers={tableHeaders}
          data={transactionsData?.pages.flatMap((page) => page.items)}
          isLoading={transactionsDataLoading || isLoadingCatalogRelationship}
          rowBuilder={buildRow}
          onRowClick={(catalogMessage) =>
            navigate(
              routeToPage(
                catalogMessage.messageId ? PriceCatalogRoutes.viewMessage : PriceCatalogRoutes.viewSenderEnvelope,
                { id: catalogMessage.id },
              ),
            )
          }
          emptyState={
            <ListTable.EmptyState cols={tableHeaders.length}>
              {t('priceCatalogs:catalogList.emptyState.title')}
            </ListTable.EmptyState>
          }
        />
      </>
    </SlideOver>
  );
};
