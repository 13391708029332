import { FC, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/Display/Tooltip/Tooltip';
import { CheckCircleIcon, NoSymbolIcon } from '@heroicons/react/24/outline';
import { InProgresCircle } from 'components/CustomIcons/InProgressCircle';

type RowStatusProps = {
  statusColumn: string | undefined;
  currentColumn: string;
  currentPath: string;
  tooltips: {
    deleted: string;
    partial: string;
    complete: string;
  };
  isProcurosAdded: boolean;
};

type StatusType = 'deleted' | 'partial' | 'complete';

const iconComponents = {
  deleted: NoSymbolIcon,
  partial: InProgresCircle,
  complete: CheckCircleIcon,
};

const iconClasses: Record<StatusType, string> = {
  deleted: 'size-5 text-gray-400',
  partial: 'size-5 text-yellow-500',
  complete: 'size-5 text-procuros-green-500',
};

const getStatus = (value: number, valueToCompare: number | undefined, isAdded: boolean): StatusType => {
  if (isAdded || valueToCompare === undefined) return 'complete';
  if (value === 0 || (!value && value !== valueToCompare)) return 'deleted';
  if (value < valueToCompare) return 'partial';
  return 'complete';
};

export const RowStatus: FC<RowStatusProps> = ({
  statusColumn,
  currentColumn,
  currentPath,
  tooltips,
  isProcurosAdded,
}) => {
  const finalPath = currentPath.replace(currentColumn, statusColumn || '');
  const orderedQuantityPath = currentPath.replace(currentColumn, 'ordered_quantity');
  const [value, orderedQuantityValue] = useWatch({
    name: [finalPath, orderedQuantityPath],
  });

  const status = useMemo(() => {
    return getStatus(value, orderedQuantityValue, isProcurosAdded);
  }, [value, orderedQuantityValue, isProcurosAdded]);

  const IconComponent = iconComponents[status];
  return (
    <Tooltip>
      <TooltipTrigger>
        <IconComponent className={iconClasses[status]} />
      </TooltipTrigger>
      <TooltipContent>{tooltips[status]}</TooltipContent>
    </Tooltip>
  );
};
