import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useMemo } from 'react';
import { useSenderProcessSpecification } from 'services/repositories/relationships/relationships';
import { DocumentType, ProcessSpecificationDTO } from 'support/types';
import { ENV } from 'services/environment/environment';
import { useOCRStore } from 'stores/ocr/ocr';

const DOCUMENTS_WITH_OCR = [DocumentType.shippingNotice, DocumentType.invoice];

export const useShouldShowOcr = (documentType: DocumentType | undefined): boolean => {
  const shippingNoticeOCREnabled = useFeatureFlagEnabled('ocr-shipping-notice');
  const invoiceOCREnabled = useFeatureFlagEnabled('ocr-invoice');
  const { overrideEnabled } = useOCRStore();

  return useMemo(() => {
    if (!documentType || !DOCUMENTS_WITH_OCR.includes(documentType)) {
      return false;
    }
    
        if (overrideEnabled) {
      return true;
    }
    switch (documentType) {
      case DocumentType.shippingNotice:
        return Boolean(shippingNoticeOCREnabled);
      case DocumentType.invoice:
        return Boolean(invoiceOCREnabled);
      default:
        return false;
    }
  }, [documentType, overrideEnabled, shippingNoticeOCREnabled, invoiceOCREnabled]);
};

const isBetaOCR = (senderProcessSpecification: ProcessSpecificationDTO | undefined) => {
  if (ENV.APP_ENV === 'staging') {
    return false;
  }
  return !senderProcessSpecification?.meta?.ocr?.enabled;
};

export const useIsBetaOCR = ({
  targetRelationshipId,
  sourceMessageId,
}: {
  targetRelationshipId: string | undefined;
  sourceMessageId: string | undefined;
}) => {
  const { overrideEnabled } = useOCRStore();
  const { data: senderProcessSpecification, isLoading } = useSenderProcessSpecification({
    variables: { relationshipId: targetRelationshipId, sourceMessageId },
  });

  return { isLoading, isBeta: overrideEnabled ? false : isBetaOCR(senderProcessSpecification) };
};
