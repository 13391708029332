import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addNotification, NotificationType } from 'stores/notifications/notifications';
import { ColumnTypesInternal } from 'components/DataTable/Types';
import { DocumentType, ProcessSpecificationDTO, RelationshipV2 } from 'support/types';
import { useWebEDIStore } from 'stores/webedi/webedi';
import { OcrResult, MergeStats, UnmatchedLineItem } from '../../types';
import { calculateMergeStats } from '../../utils';
import { ConfirmationStage } from './components/ConfirmationStage';
import { PickLineItemsStage } from './components/PickLineItemsStage';
import { UploadStage } from './components/UploadStage';
import classNames from 'classnames';

enum ExtractionStages {
  UPLOAD,
  PICK_LINE_ITEMS,
  CONFIRMATION,
}

type ExtractorProps = {
  senderProcessSpecification: ProcessSpecificationDTO;
  targetRelationship: RelationshipV2;
  onApplyOcrExtraction: (result: OcrResult) => void;
  setOpen: (open: boolean) => void;
  sourceDocumentId: string | undefined;
};

export const Extractor = ({
  setOpen,
  senderProcessSpecification,
  targetRelationship,
  onApplyOcrExtraction,
  sourceDocumentId,
}: ExtractorProps) => {
  const { t } = useTranslation();
  const canonical = useWebEDIStore((state) => state.initialCanonical);
  const receiverProcessSpecificationFields = useWebEDIStore((state) => state.processSpecificationFields);
  const [stage, setStage] = useState<ExtractionStages>(ExtractionStages.UPLOAD);
  const [documentHash, setDocumentHash] = useState<string>();
  const [mergedData, setMergedData] = useState<any>();
  const [mergeStats, setMergeStats] = useState<MergeStats>({
    matched: 0,
    added: 0,
    missing: 0,
  });
  const [unmatchedLineItems, setUnmatchedLineItems] = useState<Array<UnmatchedLineItem>>([]);

  const onBack = useCallback(() => {
    switch (stage) {
      case ExtractionStages.PICK_LINE_ITEMS:
        setStage(ExtractionStages.UPLOAD);
        break;
      case ExtractionStages.CONFIRMATION:
        if (unmatchedLineItems.length > 0) {
          setStage(ExtractionStages.PICK_LINE_ITEMS);
          break;
        }
        setStage(ExtractionStages.UPLOAD);
        break;
    }
  }, [stage, unmatchedLineItems]);

  const onConfirm = useCallback(() => {
    const finalMergedData = getFinalMergedData(
      mergedData,
      unmatchedLineItems,
      targetRelationship.message_type as DocumentType,
    );
    onApplyOcrExtraction({
      documentHash: documentHash ?? '',
      data: finalMergedData,
    });
    setOpen(false);
    addNotification(
      {
        title: t('webedi:documentExtractor.notifications.success.title'),
        subtitle: t('webedi:documentExtractor.notifications.success.subtitle'),
      },
      NotificationType.success,
    );
  }, [mergedData, unmatchedLineItems, targetRelationship.message_type, onApplyOcrExtraction, documentHash, setOpen, t]);

  const onApply = useCallback(
    (newUnmatchedLineItems: Array<UnmatchedLineItem>) => {
      setMergeStats(
        calculateMergeStats(mergedData, newUnmatchedLineItems, targetRelationship.message_type as DocumentType),
      );
      setUnmatchedLineItems(newUnmatchedLineItems);
      setStage(ExtractionStages.CONFIRMATION);
    },
    [mergedData, targetRelationship.message_type],
  );

  const onExtracted = useCallback(
    (data: any, documentHash: string) => {
      const unmatchedLineItems = getUnmatchedLineItems(data.merged, targetRelationship.message_type as DocumentType);
      setMergedData(data.merged);
      setDocumentHash(documentHash);

      if (unmatchedLineItems.length > 0) {
        setUnmatchedLineItems(unmatchedLineItems);
        setStage(ExtractionStages.PICK_LINE_ITEMS);
        return;
      }

      setUnmatchedLineItems([]);
      setMergeStats(calculateMergeStats(data.merged, [], targetRelationship.message_type as DocumentType));
      setStage(ExtractionStages.CONFIRMATION);
    },
    [targetRelationship.message_type],
  );

  if (!receiverProcessSpecificationFields) return null;

  return (
    <div
      className={classNames('flex max-h-[800px] flex-col gap-4 overflow-auto p-6 w-full', {
        'h-[75vh] w-[75vw]': stage === ExtractionStages.UPLOAD,
      })}
    >
      {stage === ExtractionStages.UPLOAD && (
        <UploadStage
          senderSpec={senderProcessSpecification}
          receiverSpec={{ fields: receiverProcessSpecificationFields }}
          targetRelationship={targetRelationship}
          documentType={targetRelationship.message_type}
          initialData={canonical}
          onExtracted={onExtracted}
          sourceDocumentId={sourceDocumentId}
        />
      )}
      {stage === ExtractionStages.PICK_LINE_ITEMS && (
        <PickLineItemsStage
          onBack={onBack}
          onApply={onApply}
          unmatchedLineItems={unmatchedLineItems}
          documentType={targetRelationship.message_type as DocumentType}
        />
      )}
      {stage === ExtractionStages.CONFIRMATION && (
        <ConfirmationStage mergeStats={mergeStats} onBack={onBack} onConfirm={onConfirm} />
      )}
    </div>
  );
};

const getUnmatchedLineItems = (mergedData: any, documentType: DocumentType) => {
  const unmatchedLineItems: Array<UnmatchedLineItem> = [];

  if (documentType === DocumentType.shippingNotice) {
    // For shipping notices, line items are nested inside transport_units
    mergedData.transport_units?.forEach((unit: any, unitIndex: number) => {
      unit.line_items?.forEach((lineItem: any, lineItemIndex: number) => {
        if (lineItem._ocr_internal?.matched === false && lineItem._ocr_internal?.extracted === true) {
          unmatchedLineItems.push({
            path: `transport_units[${unitIndex}].line_items[${lineItemIndex}]`,
            item: lineItem,
            keep: 'true',
          });
        }
      });
    });
  } else {
    // For other document types, line items are at the root level
    mergedData.line_items?.forEach((lineItem: any, lineItemIndex: number) => {
      if (lineItem._ocr_internal?.matched === false && lineItem._ocr_internal?.extracted === true) {
        unmatchedLineItems.push({
          path: `line_items[${lineItemIndex}]`,
          item: lineItem,
          keep: 'true',
        });
      }
    });
  }

  return unmatchedLineItems;
};

const getFinalMergedData = (
  mergedData: any,
  unmatchedLineItems: Array<UnmatchedLineItem>,
  documentType: DocumentType,
) => {
  // Create a deep copy of the merged data
  const finalMergedData = { ...mergedData };

  // For shipping notices, line items are nested inside transport_units
  // Helper function to process line items
  const processLineItems = (lineItems: Array<any>, pathPrefix: string, unitIndex?: number) => {
    return lineItems
      .map((lineItem: any, lineItemIndex: number) => {
        const path =
          unitIndex !== undefined
            ? `${pathPrefix}[${unitIndex}].line_items[${lineItemIndex}]`
            : `${pathPrefix}[${lineItemIndex}]`;

        const unmatchedLineItem = unmatchedLineItems.find((unmatched) => unmatched.path === path);

        return {
          ...lineItem,
          _internal: {
            ...lineItem._internal,
            matched: !unmatchedLineItem,
            keepUnmatched: unmatchedLineItem?.keep === 'true',
            [ColumnTypesInternal.procuros_added]: !!unmatchedLineItem,
          },
        };
      })
      .filter((lineItem: any) => lineItem._internal.matched === true || lineItem._internal.keepUnmatched === true);
  };

  if (documentType === DocumentType.shippingNotice) {
    // For shipping notices, line items are nested inside transport_units
    finalMergedData.transport_units = mergedData.transport_units
      .map((unit: any, unitIndex: number) => ({
        ...unit,
        line_items: processLineItems(unit.line_items, 'transport_units', unitIndex),
      }))
      .filter((unit: any) => unit.line_items.length > 0);
  } else {
    // For other document types, line items are at the root level
    finalMergedData.line_items = processLineItems(mergedData.line_items, 'line_items');
  }

  return finalMergedData;
};
