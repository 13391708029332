import { Filter } from 'components/ListTable/types';
import { InboxStatsDTO, MessageDTO, PaginationDTO } from 'support/types';

import { createInfiniteQuery } from 'react-query-kit';
import { fetchData } from 'services/http/http';

const inboxEndpoints = {
  pendingOrders: '/v1/inbox/new-orders',
  pendingOrderResponses: '/v1/inbox/pending-order-responses',
  pendingShippingNotices: '/v1/inbox/pending-shipping-notices',
  pendingInvoices: '/v1/inbox/pending-invoices',
};

type InboxMessagesResponse = PaginationDTO<MessageDTO>;

export const INBOX_QUERY_KEY = '/inbox';
export const useInfiniteInboxMessages = createInfiniteQuery<InboxMessagesResponse, { query?: Filter }, Error>({
  primaryKey: INBOX_QUERY_KEY,
  queryFn: async ({ queryKey: [_, variables], pageParam }) => {
    const typeFilter = variables.query?.filterValues?.find((filter) => filter.key === 'type');
    if (!typeFilter) {
      return Promise.reject();
    }

    let endpoint = inboxEndpoints[typeFilter.value as keyof InboxStatsDTO['data']];

    if (pageParam) {
      endpoint += `?cursor=${pageParam}`;
    }

    const { data } = await fetchData<InboxMessagesResponse>(endpoint);
    return data;
  },
  enabled: (data, variables) => !!variables.query,
  getNextPageParam: (lastPage) => lastPage.nextCursor,
  getPreviousPageParam: (_, allPages) => allPages.at(-1)?.previousCursor,
});
