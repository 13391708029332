import { createBrowserRouter, Navigate } from 'react-router-dom';
import { NotFoundPage } from 'components/Page/NotFoundPage';
import Root from './Root';
import {
  AuthRoutes,
  ConnectionsRoutes,
  DashboardRoutes,
  InboxRoutes,
  IntegrationProcessRoutes,
  PricingConfigRoutes,
  SettingsRoutes,
  NetworkRoutes,
  TransactionsRoutes,
  PriceCatalogRoutes,
} from 'support/types';
import { Register } from 'pages/Auth/Register/Register';
import { Login } from 'pages/Auth/Login/Login';
import { ForgotPassword } from 'pages/Auth/ForgotPassword/ForgotPassword';
import { ResetPassword } from 'pages/Auth/ResetPassword/ResetPassword';
import { CreateAccount } from 'pages/Auth/CreateAccount/CreateAccount';
import { CompanyData } from 'pages/Settings/CompanyData/CompanyData';
import { ProfileAndPreferences } from 'pages/Settings/ProfileAndPreferences/ProfileAndPreferences';
import { CreateDocument } from 'pages/Transactions/CreateDocument/CreateDocument';
import { MessageViewPage } from 'pages/Transactions/TransactionViewPage/MessageViewPage/MessageViewPage';
import { Password } from 'pages/Settings/Password/Password';
import { EditPricingConfigsPage } from 'pages/PriceConfigs/EditPricingConfigs';
import { PricingConfigsPage } from 'pages/PriceConfigs/PricingConfigsPage';
import { PrivateRoute } from 'components/PrivateRoute/PrivateRoute';
import { Inbox } from 'pages/Inbox/Inbox';
import { NewPricingConfigsPage } from 'pages/PriceConfigs/NewPricingConfigs';
import { FEATURE_FLAGS } from 'support/helpers/featureFlags/featureFlags';
import { PartnerFeatureFlagGuard } from 'components/PartnerFeatureFlagGuard/PartnerFeatureFlagGuard';
import { MainErrorBoundary } from 'components/MainErrorBoundary/MainErrorBoundary';
import { Connections } from 'pages/Connections/Connections';
import { TeamMembers } from 'pages/Settings/TeamMembers/TeamMembers';
import { TradePartners } from 'pages/TradePartners/TradePartners';
import { Transactions } from 'pages/Transactions/Transactions';
import { SenderEnvelopeViewPage } from 'pages/Transactions/TransactionViewPage/SenderEnvelopeView/SenderEnvelopeViewPage';
import { PriceCatalogsPage } from 'pages/PriceCatalogs/PriceCatalogsPage';
import { BlockedTransactions, ExpiredTransactions } from 'pages/Transactions/BlockedTransactions/BlockedTransactions';
import { EditDocument } from 'pages/Transactions/EditDocument/EditDocument';
import { ExpiredLink } from 'pages/Auth/ExpiredLink/ExpiredLink';
import { lazy, Suspense } from 'react';
import { Page } from 'components/Page/Page';
import { PylonLogin } from 'pages/Auth/PylonLogin/PylonLogin';
import { Dashboard } from 'pages/Dashboard/Dashboard';

const ProcessTransformation = lazy(() =>
  import('pages/ProcessTransformation/ProcessTransformation').then((module) => ({
    default: module.ProcessTransformation,
  })),
);
export const router = createBrowserRouter(
  [
    {
      element: <Root />,
      path: DashboardRoutes.root,
      errorElement: <MainErrorBoundary />,
      children: [
        {
          path: '*',
          element: <NotFoundPage />,
        },
        {
          path: DashboardRoutes.legacyRoot,
          element: <Navigate to={DashboardRoutes.root} replace />,
        },
        {
          path: SettingsRoutes.root,
          element: <Navigate to={SettingsRoutes.companyData} replace />,
        },
        {
          path: AuthRoutes.login,
          element: <Login />,
        },
        {
          path: AuthRoutes.register,
          element: <Register />,
        },
        {
          path: AuthRoutes.forgotPassword,
          element: <ForgotPassword />,
        },
        {
          path: AuthRoutes.resetPassword,
          element: <ResetPassword />,
        },
        {
          path: AuthRoutes.createAccount,
          element: <CreateAccount />,
        },
        {
          path: AuthRoutes.expiredLink,
          element: <ExpiredLink />,
        },
        {
          path: AuthRoutes.pylonLogin,
          element: (
            <PrivateRoute>
              <PylonLogin />
            </PrivateRoute>
          ),
        },
        {
          index: true,
          element: (
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          ),
        },
        {
          path: InboxRoutes.root,
          element: (
            <PrivateRoute>
              <Inbox />
            </PrivateRoute>
          ),
        },
        {
          path: NetworkRoutes.deprecatedRoot,
          element: <Navigate to={NetworkRoutes.root} replace />,
        },
        {
          path: NetworkRoutes.root,
          element: (
            <PrivateRoute>
              <TradePartners />
            </PrivateRoute>
          ),
        },
        {
          path: PriceCatalogRoutes.root,
          element: (
            <PrivateRoute>
              <PartnerFeatureFlagGuard
                to={DashboardRoutes.root}
                featureFlags={[FEATURE_FLAGS.TEMPORARY_PRICE_CATALOGS]}
              >
                <PriceCatalogsPage />
              </PartnerFeatureFlagGuard>
            </PrivateRoute>
          ),
        },
        {
          path: PriceCatalogRoutes.relationshipDetails,
          element: (
            <PrivateRoute>
              <PartnerFeatureFlagGuard
                to={DashboardRoutes.root}
                featureFlags={[FEATURE_FLAGS.TEMPORARY_PRICE_CATALOGS]}
              >
                <PriceCatalogsPage />
              </PartnerFeatureFlagGuard>
            </PrivateRoute>
          ),
        },
        {
          path: PriceCatalogRoutes.viewMessage,
          element: (
            <PrivateRoute>
              <PartnerFeatureFlagGuard
                to={DashboardRoutes.root}
                featureFlags={[FEATURE_FLAGS.TEMPORARY_PRICE_CATALOGS]}
              >
                <MessageViewPage />
              </PartnerFeatureFlagGuard>
            </PrivateRoute>
          ),
        },
        {
          path: PriceCatalogRoutes.viewSenderEnvelope,
          element: (
            <PrivateRoute>
              <PartnerFeatureFlagGuard
                to={DashboardRoutes.root}
                featureFlags={[FEATURE_FLAGS.TEMPORARY_PRICE_CATALOGS]}
              >
                <SenderEnvelopeViewPage />
              </PartnerFeatureFlagGuard>
            </PrivateRoute>
          ),
        },
        {
          path: PriceCatalogRoutes.create,
          element: (
            <PrivateRoute>
              <PartnerFeatureFlagGuard
                to={DashboardRoutes.root}
                featureFlags={[FEATURE_FLAGS.TEMPORARY_PRICE_CATALOGS]}
              >
                <CreateDocument />
              </PartnerFeatureFlagGuard>
            </PrivateRoute>
          ),
        },
        {
          path: PricingConfigRoutes.root,
          element: (
            <PrivateRoute>
              <PricingConfigsPage />
            </PrivateRoute>
          ),
        },
        {
          path: PricingConfigRoutes.view,
          element: (
            <PrivateRoute>
              <EditPricingConfigsPage />
            </PrivateRoute>
          ),
        },
        {
          path: PricingConfigRoutes.new,
          element: (
            <PrivateRoute>
              <NewPricingConfigsPage />
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.root,
          element: (
            <PrivateRoute>
              <Transactions />
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.blocked,
          element: (
            <PrivateRoute>
              <BlockedTransactions />
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.expired,
          element: (
            <PrivateRoute>
              <ExpiredTransactions />
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.viewMessage,
          element: (
            <PrivateRoute>
              <MessageViewPage />
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.viewSenderEnvelope,
          element: (
            <PrivateRoute>
              <SenderEnvelopeViewPage />
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.createStandalone,
          element: (
            <PrivateRoute>
              <CreateDocument />
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.create,
          element: (
            <PrivateRoute>
              <CreateDocument />
            </PrivateRoute>
          ),
        },
        {
          path: TransactionsRoutes.edit,
          element: (
            <PrivateRoute>
              <EditDocument />
            </PrivateRoute>
          ),
        },
        {
          path: ConnectionsRoutes.testRelationshipMessageDetails,
          element: (
            <PrivateRoute>
              <Connections />
            </PrivateRoute>
          ),
        },
        {
          path: ConnectionsRoutes.testRelationshipTransactionError,
          element: (
            <PrivateRoute>
              <Connections />
            </PrivateRoute>
          ),
        },
        {
          path: ConnectionsRoutes.testRelationship,
          element: (
            <PrivateRoute>
              <Connections />
            </PrivateRoute>
          ),
        },
        {
          path: ConnectionsRoutes.partnerDetails,
          element: (
            <PrivateRoute>
              <Connections />
            </PrivateRoute>
          ),
        },
        {
          path: ConnectionsRoutes.relationshipDetails,
          element: (
            <PrivateRoute>
              <Connections />
            </PrivateRoute>
          ),
        },
        {
          path: ConnectionsRoutes.root,
          element: (
            <PrivateRoute>
              <Connections />
            </PrivateRoute>
          ),
        },
        {
          path: SettingsRoutes.companyData,
          element: (
            <PrivateRoute>
              <CompanyData />
            </PrivateRoute>
          ),
        },
        {
          path: SettingsRoutes.teamMembers,
          element: (
            <PrivateRoute>
              <TeamMembers />
            </PrivateRoute>
          ),
        },
        {
          path: SettingsRoutes.profileAndPreferences,
          element: (
            <PrivateRoute>
              <ProfileAndPreferences />
            </PrivateRoute>
          ),
        },
        {
          path: SettingsRoutes.password,
          element: (
            <PrivateRoute>
              <Password />
            </PrivateRoute>
          ),
        },

        {
          path: IntegrationProcessRoutes.transformation,
          element: (
            <PrivateRoute>
              <Suspense fallback={<Page isLoading>{null}</Page>}>
                <ProcessTransformation />
              </Suspense>
            </PrivateRoute>
          ),
        },
      ],
    },
  ],
  {
    future: {
      // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
      v7_normalizeFormMethod: true,
    },
  },
);
