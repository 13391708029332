import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { DisplayModes } from 'components/DataTable/Types';
import { AlertSection } from 'components/Display/Alert';
import { Alert } from 'components/Display/Alert/Alert';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { Button } from 'components/Form/Button/Button';
import { Translate } from 'components/Translate/Translate';
import { useTranslation } from 'react-i18next';
import { useWebEDIErrors } from 'services/webediErrors/webediErrors';
import { DATA_CHECK_ERROR_CODE } from 'support/helpers/const/const';
import { MessageV2DTO } from 'support/types';

export const MessageErrorBanner = ({
  mode,
  blockingError,
}: {
  mode: DisplayModes;
  blockingError: MessageV2DTO['blocking_error'];
}) => {
  const { t } = useTranslation();
  const { numberOfErrors, moveToNextWebEDIError, moveToPreviousWebEDIError, focusOnCurrentError } = useWebEDIErrors();
  if (blockingError && blockingError.code !== DATA_CHECK_ERROR_CODE) {
    return (
      <Alert withoutIcon severity="neutral" fullWidth>
        {blockingError.description ? (
          <AlertSection severity="neutral" title={blockingError.title}>
            <Translate i18nKey={blockingError.description} />
          </AlertSection>
        ) : null}
        {blockingError.howToFix ? (
          <AlertSection severity="neutral" title={t('common:blockingError.howToFix')}>
            <Translate i18nKey={blockingError.howToFix} />
          </AlertSection>
        ) : null}
      </Alert>
    );
  }

  if (numberOfErrors === 0) return null;

  return (
    <Alert withoutIcon severity="error" fullWidth>
      <div className="flex w-full items-center justify-between">
        <span>
          {mode === DisplayModes.view
            ? t('webedi:viewDocument.errorBanner.fix')
            : t('webedi:editDocument.errorBanner.fix')}
        </span>
        <div className="flex gap-1">
          <Badge type="indicator" color={BadgeColor.red}>
            {mode === DisplayModes.view
              ? t('webedi:viewDocument.errorBanner.badge', { count: numberOfErrors, numberOfErrors })
              : t('webedi:editDocument.errorBanner.badge', { count: numberOfErrors, numberOfErrors })}
          </Badge>
          {numberOfErrors > 1 ? (
            <>
              <Button
                variant="secondary-danger"
                size="extra-small"
                onClick={moveToPreviousWebEDIError}
                iconOnly
                LeftIcon={ChevronUpIcon}
                analyticsId="webedi:errors_previous"
              />
              <Button
                variant="secondary-danger"
                size="extra-small"
                onClick={moveToNextWebEDIError}
                iconOnly
                LeftIcon={ChevronDownIcon}
                analyticsId="webedi:errors_next"
              />
            </>
          ) : (
            <Button
              variant="secondary-danger"
              size="extra-small"
              onClick={focusOnCurrentError}
              analyticsId="webedi:errors_focus_current"
            >
              {t('webedi:viewDocument.errorBanner.focusOnError')}
            </Button>
          )}
        </div>
      </div>
    </Alert>
  );
};
