import { datachecks, ui } from '@procuros/datachecks';
import isEqual from 'lodash/isEqual';
import { getCanonical } from 'services/datachecks/datachecks';
import { enrichDocument } from 'support/helpers/transformation/transformer';
import { _tempAddProcurosAdded, orderLineItems } from './utils';
import { DocumentType, ProcessSpecificationDTO, ValueOf } from 'support/types';
import { logError } from 'services/logging/logging';

let previousEnrichedCanonical: Record<string, any> | null = null;

export type ProcessFormDataArgs = {
  data: Record<string, any>;
  receiverProcessSpecificationFields?: ProcessSpecificationDTO['fields'];
  documentType: ValueOf<DocumentType> | DocumentType;
};

export type ProcessFormResult = {
  data: Record<string, any>;
  uiConfig: Array<ui.Section>;
};

export const getEnrichedFlattenDataAndUIConfig = async ({
  data,
  receiverProcessSpecificationFields,
  documentType,
}: ProcessFormDataArgs): Promise<ProcessFormResult | undefined> => {
  if (!receiverProcessSpecificationFields || !data || Object.keys(data).length === 0) {
    throw new Error('Missing required data for processing');
  }

  try {
    const { canonical } = await getCanonical(receiverProcessSpecificationFields, addIndexesToData(data));
    const { data: enrichedDocument } = enrichDocument({
      data: canonical,
      messageType: documentType as DocumentType,
    });

    if (!isEqual(enrichedDocument, previousEnrichedCanonical)) {
      previousEnrichedCanonical = enrichedDocument;
      return getFlattenDataAndUIConfig(receiverProcessSpecificationFields, enrichedDocument);
    }
  } catch (error) {
    logError(error);
    throw error;
  }
};

const getFlattenDataAndUIConfig = async (
  receiverProcessSpecificationFields: ProcessSpecificationDTO['fields'],
  enrichedDocument: Record<string, any>,
) => {
  const uiResult = await ui.view(
    { fields: receiverProcessSpecificationFields as Array<datachecks.SpecFieldT> },
    enrichedDocument,
  );

  const hasTransportUnits = 'transport_units' in uiResult.values && uiResult.values.transport_units?.length > 0;
  const hasLineItems = 'line_items' in uiResult.values && uiResult.values.line_items?.length > 0;
  if (hasTransportUnits && hasLineItems) {
    uiResult.values.line_items = orderLineItems(uiResult.values.line_items);
  }

  return {
    data: _tempAddProcurosAdded(uiResult.values),
    uiConfig: uiResult.view,
  };
};

const addIndexesToData = (data: Record<string, any>) => {
  const { line_items } = data;
  if (line_items) {
    data.line_items = addIndexToLineItems(line_items);
  }
  return data;
};

const addIndexToLineItems = (lineItems: Array<Record<string, any>>) => {
  return lineItems.map((lineItem, index) => {
    return {
      ...lineItem,
      _internal: {
        ...lineItem._internal,
        index: index + 1,
      },
    };
  });
};
