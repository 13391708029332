import { FieldErrors, FieldPath, FieldValues, Path, UseFormSetError } from 'react-hook-form';
import { ErrorDialog } from 'support/types';
import { TFunction } from 'i18next';

const isInputError = <T extends FieldValues>(key: string, inputData: T): key is FieldPath<T> => {
  // Check if key is in dot notion (meaning nested)
  const firstDotAt = key.indexOf('.');

  // If key is not nested, check if it is in the input data
  if (firstDotAt === -1) {
    return key in inputData;
  }

  // Recursively check if the key is in the input data
  const firstKey = key.substring(0, firstDotAt);
  const remainingKey = key.substring(firstDotAt + 1);
  if (firstKey in inputData) {
    return isInputError(remainingKey, inputData[firstKey]);
  }
  return false;
};

export const mapFormErrorsToString = (errors: FieldErrors): Array<string> => {
  const errorMessages: Array<string> = [];

  Object.keys(errors).forEach((errorKey: string) => {
    const message = errors[errorKey]?.message;

    if (message) {
      errorMessages.push(message.toString());
    }
  });

  return errorMessages;
};

const processError = (message: string, t: TFunction): string => {
  switch (message) {
    case ErrorDialog.internalServer:
      return t('auth:errors.internal');
    case ErrorDialog.authentication:
      return t('auth:errors.authentication');
    default:
      return message;
  }
};

type ProcessSubmissionErrorsArgs<T extends FieldValues | undefined> = {
  error: Error;
  setInputError: T extends FieldValues ? UseFormSetError<T> : undefined;
  setNonInputErrors:
    | undefined
    | (((callback: (prev: Array<string>) => Array<string>) => void) & ((newValue: Array<string>) => void));
  defaultData: T;
  t: TFunction;
};

export const processSubmissionErrors = <T extends FieldValues | undefined>({
  error,
  setInputError,
  setNonInputErrors,
  defaultData,
  t,
}: ProcessSubmissionErrorsArgs<T>) => {
  if (error instanceof ValidationError && Object.keys(error.errors).length > 0) {
    Object.keys(error.errors).forEach((key) => {
      if (defaultData && isInputError(key, defaultData)) {
        setInputError?.(key as Path<T>, {
          type: 'custom',
          message: (error as ValidationError).errors[key].join('\n'),
        });
        return;
      }
      setNonInputErrors?.((prev) => [...prev, ...(error as ValidationError).errors[key]]);
    });
    return;
  }
  setNonInputErrors?.([processError(error.message, t)]);
};

export class BackendError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;

    Object.setPrototypeOf(this, BackendError.prototype);
  }
}

export class NotFoundError extends BackendError {
  constructor(message: string) {
    super(message, 404);

    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}

export class BadCallError extends BackendError {
  constructor(message: string) {
    super(message, 400);

    Object.setPrototypeOf(this, BadCallError.prototype);
  }
}

export class UnauthorizedError extends BackendError {
  constructor(message: string) {
    super(message, 401);

    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}

export class ForbiddenError extends BackendError {
  constructor(message: string) {
    super(message, 403);

    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}

export class ConflictError extends BackendError {
  constructor(message: string) {
    super(message, 409);

    Object.setPrototypeOf(this, ConflictError.prototype);
  }
}

export class ValidationError extends BackendError {
  errors: Record<string, Array<string>>;

  constructor(message: string, errors: Record<string, Array<string>>) {
    super(message, 422);
    this.errors = errors;

    Object.setPrototypeOf(this, ValidationError.prototype);
  }
}

export class GenericError extends Error {
  otherInformation: unknown;

  constructor(message: string, otherInformation: unknown = {}) {
    super(message);
    this.otherInformation = otherInformation;

    Object.setPrototypeOf(this, GenericError.prototype);
  }
}

export const isDuplicatedDocumentError = (error: Error): boolean => {
  return error.message === 'PayloadAlreadyReceivedException';
};
