import { BasePopupV2 } from 'components/Display/BasePopup/BasePopupV2';
import { SetupOCRExtractor } from './SetupOCRExtractor/SetupOCRExtractor';
import { useDocumentCreationRelationship } from 'support/helpers/relationships/relationships';
import { DocumentType } from 'support/types';
import { useSenderProcessSpecification } from 'services/repositories/relationships/relationships';

type OCRSetupModalProps = {
  setOpen: (open: boolean) => void;
  open: boolean;
  sourceMessageId: string;
  documentType: DocumentType | undefined;
};

export const OCRSetupModal = ({ open, setOpen, sourceMessageId, documentType }: OCRSetupModalProps) => {
  const targetRelationship = useDocumentCreationRelationship({
    relationshipId: undefined, //used only to create standalone documents
    sourceMessageId,
    documentType,
  });
  const { data: senderProcessSpecification } = useSenderProcessSpecification({
    variables: { relationshipId: targetRelationship?.id, sourceMessageId },
  });

  if (!targetRelationship || !senderProcessSpecification) {
    return null;
  }

  return (
    <BasePopupV2 setOpen={setOpen} open={open} width="fit-content" shouldGrowIndefinitely>
      <SetupOCRExtractor
        senderProcessSpecification={senderProcessSpecification}
        targetRelationship={targetRelationship}
        setOpen={setOpen}
        sourceDocumentId={sourceMessageId}
      />
    </BasePopupV2>
  );
};
