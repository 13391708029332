import { DocumentType } from 'support/types';
import { useMessageWithProcessSpecification } from 'services/repositories/messages/messages';
import { useEffect } from 'react';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { useOCRStore } from 'stores/ocr/ocr';
import { useAdminReferrer } from 'support/helpers/hooks/useIsAdminReferrer';
import { useImpersonatePartner } from 'support/helpers/users/users';

export const useIsAdminOCRTest = (sourceMessageId?: string) => {
  const { setOverrideEnabled } = useOCRStore();
  const { data: currentPartner } = useCurrentPartner();
  const { changePartner } = useImpersonatePartner();
  const { partnerId } = useOCRSenderPartner(sourceMessageId);
  const { isFromAdmin } = useAdminReferrer();

  useEffect(() => {
    const shouldChangePartner = isFromAdmin && partnerId && currentPartner && partnerId !== currentPartner.id;

    if (isFromAdmin && partnerId) {
      setOverrideEnabled(true);

      if (shouldChangePartner) {
        changePartner(partnerId);
      }
    }
  }, [changePartner, currentPartner, partnerId, setOverrideEnabled, isFromAdmin]);
};

const useOCRSenderPartner = (sourceMessageId?: string) => {
  const { data: message } = useMessageWithProcessSpecification({
    variables: {
      messageId: sourceMessageId,
    },
  });

  const getPartnerId = () => {
    if (!message?.data) return null;
    return message.data.type === DocumentType.order
      ? message.data.receiver_partner?.id
      : message.data.sender_partner?.id;
  };

  return {
    partnerId: getPartnerId(),
    message,
  };
};
