import { useReactTable } from '@tanstack/react-table';
import { isActionColumn } from '../hooks/useIsActionColumn';
import { Th } from './Th';
import { setColumnSizes } from '../layoutHelpers/setColumnSizes';

type TableHeaderProps<TData> = {
  table: ReturnType<typeof useReactTable<TData>>;
  showCellBorders: boolean;
  columnsWidth: string;
};

export const TableHeader = <TData = unknown,>({ table, showCellBorders, columnsWidth }: TableHeaderProps<TData>) => {
  return (
    <thead className="sticky left-0 top-0 z-10">
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => {
            const isAction = isActionColumn(header.column.columnDef);
            const fixedWidth = header.column.columnDef.meta?.style?.width;

            return (
              <Th
                isAction={isAction}
                key={header.id}
                header={header}
                betweenCellBorders={showCellBorders}
                isFirstColumn={!index}
                isLastColumn={index === headerGroup.headers.length - 1}
                ref={(thElem: HTMLTableCellElement | null) => setColumnSizes(thElem, table, header.column)}
                style={
                  !isAction
                    ? { width: fixedWidth ?? columnsWidth, minWidth: fixedWidth, maxWidth: fixedWidth }
                    : undefined
                }
              />
            );
          })}
        </tr>
      ))}
    </thead>
  );
};
