import { useMemo } from 'react';
import { useWebEDIStore } from 'stores/webedi/webedi';
import { roundNumber } from 'support/helpers/numbers/numbers';

type OrderSummaryValues = {
  totalQuantity: number;
  totalValue: number;
  lineCount: number;
  deliveryDate?: number;
};

const calculateLineItemsQuantity = (lineItems: Array<any> | undefined): number => {
  return (
    lineItems?.reduce((acc, item) => {
      if (item.line_items?.length) {
        return acc + calculateLineItemsQuantity(item.line_items);
      }

      return acc + (item.ordered_quantity ?? 0);
    }, 0) || 0
  );
};

const calculateLineItemsTotalValue = (lineItems: Array<any> | undefined): number => {
  return (
    lineItems?.reduce((acc, item) => {
      if (item.line_items?.length) {
        return acc + calculateLineItemsTotalValue(item.line_items);
      }

      return acc + (item.ordered_quantity ?? 0) * (item.item.unit_price ?? 0);
    }, 0) || 0
  );
};

export const useOrderSummary = (): OrderSummaryValues => {
  const canonical = useWebEDIStore((state) => state.canonical);

  return useMemo(
    () => ({
      totalQuantity: calculateLineItemsQuantity(canonical?.line_items),
      totalValue: roundNumber(calculateLineItemsTotalValue(canonical?.line_items), 2),
      lineCount: canonical?.line_items?.length ?? 0,
      deliveryDate: canonical?.requested_delivery_date,
    }),
    [canonical?.line_items, canonical?.requested_delivery_date],
  );
};
