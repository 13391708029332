import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Page } from 'components/Page/Page';

import { InboxOverview } from './Inbox/Inbox';
import { useDashboardStats, useFailedTransactionsCount, useInboxStats } from 'services/repositories/stats/stats';
import { useCurrentUser } from 'services/repositories/user/user';
import { DateRange, TradePartnerStats, TransactionGraphData, TransactionVolumeStats } from './types';
import { DocumentType } from 'support/types';
import { classNames } from 'support/helpers/generic/generic';
import { useConnectedPartners } from 'services/repositories/partners/partners';
import { FailedTransactions } from './FailedTransactions/FailedTransactions';
import { TradePartnerStatsDisplay } from './TradePartnerStatsDisplay/TradePartnerStatsDisplay';
import { TransactionGraph } from './TransactionGraph/TransactionGraph';
import { TransactionVolume } from './TransactionVolume/TransactionVolume';

const filterTransactionsByType = (transactions: TransactionGraphData | undefined, type: string | undefined) => {
  if (!transactions) return transactions;
  switch (type) {
    case DocumentType.order:
      return { orders: transactions.orders, invoices: [], shippingNotices: [] };
    case DocumentType.shippingNotice:
      return { orders: [], invoices: [], shippingNotices: transactions.shippingNotices };
    case DocumentType.invoice:
      return { orders: [], invoices: transactions.invoices, shippingNotices: [] };
    default:
      return transactions;
  }
};
export function Dashboard() {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState<TransactionVolumeStats['type']>();
  const [selectedPartner, setSelectedPartner] = useState<string>();
  const [currentDate] = useState(() => new Date(Date.now()).setUTCHours(0, 0, 0, 0));
  const dateRangeOptions: Array<DateRange> = useMemo(
    () => [
      {
        value: currentDate - 1000 * 60 * 60 * 24 * 7,
        interval: 1000 * 60 * 60 * 24 * 7,
        label: t('dashboard:transactions.lastSevenDays'),
        testId: 'dateRange-week',
      },
      {
        value: currentDate - 1000 * 60 * 60 * 24 * 30,
        interval: 1000 * 60 * 60 * 24 * 30,
        label: t('dashboard:transactions.lastThirtyDays'),
        testId: 'dateRange-month',
      },
      {
        value: currentDate - 1000 * 60 * 60 * 24 * 90,
        interval: 1000 * 60 * 60 * 24 * 90,
        label: t('dashboard:transactions.lastThreeMonths'),
        testId: 'dateRange-3-months',
      },
      {
        value: currentDate - 1000 * 60 * 60 * 24 * 180,
        interval: 1000 * 60 * 60 * 24 * 180,
        label: t('dashboard:transactions.lastSixMonths'),
        testId: 'dateRange-6-months',
      },
      {
        value: currentDate - 1000 * 60 * 60 * 24 * 365,
        interval: 1000 * 60 * 60 * 24 * 365,
        label: t('dashboard:transactions.lastYear'),
        testId: 'dateRange-year',
      },
    ],
    [currentDate, t],
  );
  const [dateRange, setDateRange] = useState<number>(dateRangeOptions[0].value);
  const [previousDateRange, setPreviousDateRange] = useState<number>(dateRangeOptions[0].interval);

  const { data: currentUser } = useCurrentUser();
  const { data: connectedTradePartners, isInitialLoading: connectedPartnersLoading } = useConnectedPartners();
  const { data: tradePartnerStats } = useDashboardStats({
    variables: {
      relatedPartnerId: undefined,
    },
    select: (data) => {
      return data.tradePartnerStats as TradePartnerStats;
    },
  });

  const { data: dashboardStatsData, isInitialLoading: dashboardStatsLoading } = useDashboardStats({
    variables: {
      relatedPartnerId: selectedPartner,
    },
  });

  const { data: failedTransactionCountData, isLoading: areFailedTransactionsLoading } = useFailedTransactionsCount();
  const { data: inboxStatsData, isLoading: loadingInboxStatsData } = useInboxStats({
    staleTime: 0, //make sure to override our default options
    refetchOnMount: true, //make sure to override our default options
  });

  const openTasks = Object.values(inboxStatsData?.data || {}).reduce((sum, currentValue) => sum + currentValue, 0);

  useEffect(() => {
    const index = dateRangeOptions.findIndex((date) => date.value === dateRange);
    const newPreviousDateRange = index > -1 ? dateRangeOptions[index].interval : dateRangeOptions[0].interval;
    setPreviousDateRange(newPreviousDateRange);
  }, [dateRange, dateRangeOptions]);

  const transactionsOnGraph = useMemo(() => {
    return filterTransactionsByType(dashboardStatsData?.transactions, selectedType);
  }, [dashboardStatsData?.transactions, selectedType]);

  return (
    <Page isLoading={connectedPartnersLoading || areFailedTransactionsLoading || loadingInboxStatsData}>
      <Page.Head title={t('dashboard:title', { name: currentUser?.name })} />

      <Page.Section>
        <div className={classNames('grid gap-x-12', inboxStatsData?.active ? 'grid-cols-3' : 'grid-cols-2')}>
          <div className="col-span-2 flex flex-col gap-y-6">
            <div
              className={classNames(
                'grid gap-6',
                failedTransactionCountData?.failedTransactions ? 'grid-cols-2' : 'grid-cols-1',
              )}
            >
              {failedTransactionCountData?.failedTransactions ? (
                <FailedTransactions failedTransactionsCount={failedTransactionCountData.failedTransactions} />
              ) : null}

              <TradePartnerStatsDisplay
                numberOfReadyPartners={tradePartnerStats?.ready ?? 0}
                numberOfTestingPartners={tradePartnerStats?.inTesting ?? 0}
              />
            </div>

            <TransactionVolume
              transactions={dashboardStatsData?.transactions}
              dateRangeOptions={dateRangeOptions}
              volumeDateRange={dateRange}
              previousDateRange={previousDateRange}
              onUpdateDateRange={setDateRange}
              selectedTransactionType={selectedType}
              onSelectTransactionType={setSelectedType}
              partnerOptions={connectedTradePartners?.map((partner) => ({
                label: partner.name,
                value: partner.id,
              }))}
              selectedPartner={selectedPartner}
              onSelectPartner={setSelectedPartner}
            />

            <TransactionGraph
              transactions={transactionsOnGraph}
              dateRangeOptions={dateRangeOptions}
              chartDateRange={dateRange}
              loading={dashboardStatsLoading}
            />
          </div>

          {inboxStatsData?.active && openTasks ? (
            <div>
              <InboxOverview inboxStatsData={inboxStatsData} />
            </div>
          ) : null}
        </div>
      </Page.Section>
    </Page>
  );
}
