/**
 * Recursively removes all keys named "_internal" from an object or array.
 * @param input - The input object or array to clean.
 * @returns A new object or array with "_internal" keys removed.
 */
export const removeInternalKeys = (input: any): any =>
  Array.isArray(input)
    ? input.map(removeInternalKeys)
    : input && typeof input === 'object'
      ? Object.fromEntries(
          Object.entries(input)
            .filter(([key]) => key !== '_internal')
            .map(([key, value]) => [key, removeInternalKeys(value)]),
        )
      : input;
