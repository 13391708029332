import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser, useUpdateUser } from 'services/repositories/user/user';
import { addNotification, NotificationType } from 'stores/notifications/notifications';
import { UserDTO } from 'support/types';

const PROCUROS_EMPLOYEE_EMAIL_DOMAIN = 'procuros.io';
export const isProcurosEmployee = (user: UserDTO | undefined) => {
  return Boolean(user?.email.endsWith(`@${PROCUROS_EMPLOYEE_EMAIL_DOMAIN}`));
};

type UserUpdatePayload = Parameters<ReturnType<typeof useUpdateUser>['mutateAsync']>[0];
export const useImpersonatePartner = () => {
  const { t } = useTranslation();
  const { data: currentUser } = useCurrentUser();
  const { mutateAsync: updateCurrentUser, isLoading: isMutatingCurrentUser } = useUpdateUser();

  const handleUserUpdate = useCallback(
    async (payload: UserUpdatePayload) => {
      try {
        await updateCurrentUser(payload);
        addNotification(t('auth:notifications.updateUser.success'));
        return true;
      } catch (error) {
        addNotification(t('auth:notifications.updateUser.error'), NotificationType.error);
        return false;
      }
    },
    [updateCurrentUser, t],
  );

  const changePartner = useCallback(
    (newPartnerId: string) => {
      if (!currentUser || isMutatingCurrentUser) return false;

      const userUpdatePayload: UserUpdatePayload = {
        name: currentUser.name,
        email: currentUser.email,
        language: currentUser.language,
        notifications: currentUser.notifications,
        partner_id: newPartnerId,
      };

      return handleUserUpdate(userUpdatePayload);
    },
    [currentUser, isMutatingCurrentUser, handleUserUpdate],
  );

  return {
    changePartner,
    isLoading: isMutatingCurrentUser,
    currentUser,
  };
};
