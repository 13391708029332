import classNames from 'classnames';
import { PartnerLogo } from 'components/PartnerLogo/PartnerLogo';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PartnerDTO, PartnerV2 } from 'support/types';
import { TradePartnerNameAndLogoClamping } from './types';
import useIsTruncated from 'hooks/useIsTruncated';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';

type TradePartnerNameAndLogoProps = {
  partner: PartnerV2 | PartnerDTO | null | undefined;
  showPartnerName?: boolean;
  clamping?: TradePartnerNameAndLogoClamping;
};
export const TradePartnerNameAndLogo = forwardRef<HTMLDivElement, TradePartnerNameAndLogoProps>(
  ({ partner, showPartnerName = true, clamping = TradePartnerNameAndLogoClamping.VERTICAL }, ref) => {
    const { t } = useTranslation();
    const imageUrl = partner ? getPartnerLogoUrl(partner) : null;
    const [truncatedRef, valueIsTruncated] = useIsTruncated();
    return (
      <div className="flex items-center gap-4" ref={ref}>
        <PartnerLogo imageUrl={imageUrl} size={undefined} knownPartner={Boolean(partner)} />
        {showPartnerName ? (
          <div className="flex items-center">
            <Tooltip enabled={valueIsTruncated}>
              <TooltipTrigger>
                <span
                  ref={truncatedRef}
                  className={classNames('overflow-hidden text-sm font-medium text-gray-700', {
                    'line-clamp-2 max-h-10': clamping === TradePartnerNameAndLogoClamping.VERTICAL,
                    'max-w-sm text-ellipsis': clamping === TradePartnerNameAndLogoClamping.HORIZONTAL,
                  })}
                >
                  {partner?.name || t('common:tradePartner.unknownName')}
                </span>
              </TooltipTrigger>
              <TooltipContent>{partner?.name || t('common:tradePartner.unknownName')}</TooltipContent>
            </Tooltip>
          </div>
        ) : null}
      </div>
    );
  },
);

TradePartnerNameAndLogo.displayName = 'TradePartnerNameAndLogo';

const getPartnerLogoUrl = (partner: PartnerV2 | PartnerDTO) => {
  return isPartnerV2(partner) ? partner.logo_url : partner.logoUrl;
};

const isPartnerV2 = (partner: PartnerV2 | PartnerDTO): partner is PartnerV2 => {
  return 'logo_url' in partner;
};
