import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Suspense, useEffect, useMemo } from 'react';
import './App.css';
import { useCurrentUser } from 'services/repositories/user/user';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { isPublicRoute } from 'support/helpers/navigation/navigation';
import { ENV } from 'services/environment/environment';
import { NotificationList } from 'components/NotificationList/NotificationList';
import { OfflineNotification } from 'components/OfflineNotification/OfflineNotification';
import { usePolyfills } from 'hooks/usePolyfills';
import { GlobalPopup } from 'components/GlobalPopup/GlobalPopup';
import { isNewVersionAvailable } from 'services/version/version';
import { getPreviousPath, popFromHistory, pushToHistory } from 'stores/history/history';
import { currentEnvironmentIndicatorColor } from 'support/helpers/generic/generic';
import AnalyticsPageView from 'components/Page/PageView';
import { useAnalytics } from 'support/helpers/analytics/analytics';
import { currentLanguage } from 'i18n';
import { useInfiniteIntegrations } from 'services/repositories/integrations/integrations';
import { isPotentiallyAuthenticated } from 'services/auth/auth';
import { Connectors, PartnerDTO, UserDTO } from 'support/types';

export default function Root() {
  usePolyfills();
  const { pathname } = useLocation();
  const isPrivateRoute = !isPublicRoute(pathname);
  const maybeAuthenticated = useMemo(() => isPotentiallyAuthenticated(), []);
  useIdentify(isPrivateRoute && maybeAuthenticated);

  // On every route change:
  // - Reload the page if a new version is available
  // - Add the current route to the navigation history or pop the last route if the current route is the previous one
  useEffect(() => {
    const isGoingBack = getPreviousPath() === pathname;
    if (isGoingBack) {
      popFromHistory();
    } else {
      pushToHistory(pathname);
    }
    if (isNewVersionAvailable) {
      window.location.reload();
    }
  }, [pathname]);

  return (
    <Suspense fallback="...loading">
      <Helmet>
        <html lang={currentLanguage} />
        <link rel="apple-touch-icon" sizes="180x180" href={`/assets/favicons/${ENV.APP_ENV}/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`/assets/favicons/${ENV.APP_ENV}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`/assets/favicons/${ENV.APP_ENV}/favicon-16x16.png`} />
        <link rel="manifest" href={`/assets/favicons/${ENV.APP_ENV}/site.webmanifest`} />
        <link
          rel="mask-icon"
          href={`/assets/favicons/${ENV.APP_ENV}/safari-pinned-tab.svg`}
          color={currentEnvironmentIndicatorColor}
        />
        <link rel="shortcut icon" href={`/assets/favicons/${ENV.APP_ENV}/favicon.ico`} />
        <meta name="msapplication-TileColor" content={currentEnvironmentIndicatorColor} />
        <meta name="msapplication-config" content={`/assets/favicons/${ENV.APP_ENV}/browserconfig.xml`} />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <AnalyticsPageView />
      <ScrollRestoration />
      <NotificationList />
      <GlobalPopup />
      <OfflineNotification />

      <Outlet />
    </Suspense>
  );
}

const useIdentify = (enabled: boolean) => {
  const analytics = useAnalytics();
  const { data: currentUser } = useCurrentUser({ enabled });
  const { data: currentPartner } = useCurrentPartner({ enabled });
  useIdentifyConnectors(currentUser, currentPartner);

  useEffect(() => {
    if (!currentUser || !currentPartner) {
      analytics.reset();
      return;
    }

    analytics.identify(currentUser, currentPartner);
  }, [analytics, currentUser, currentPartner]);
};

const useIdentifyConnectors = (user: UserDTO | undefined, partner: PartnerDTO | undefined) => {
  const analytics = useAnalytics();
  const { data: integrations, isLoading: isLoadingIntegrations } = useInfiniteIntegrations({
    enabled: Boolean(user && partner),
  });

  useEffect(() => {
    if (!user || !partner || isLoadingIntegrations) {
      return;
    }

    const integrationConnectors =
      integrations?.pages.flatMap((page) => page.items)?.map((integration) => integration.connector) ?? [];
    const hasWebEDIIntegration = integrationConnectors.includes(Connectors.WEB_EDI);

    analytics.track('$set', {
      $set: { hasWebEDIIntegration, connectors: integrationConnectors },
    });

    analytics.track('$groupidentify', {
      $group_type: 'tradePartner',
      $group_key: partner.id,
      $group_set: {
        hasWebEDIIntegration,
        connectors: integrationConnectors,
      },
    });
  }, [analytics, integrations?.pages, isLoadingIntegrations, user, partner]);
};
