import { useTranslation } from 'react-i18next';
import { Alert } from 'components/Display/Alert/Alert';
import { AlertSection } from 'components/Display/Alert/AlertSection';
import { checkSenderEnvelopeDropped } from 'support/helpers/transactions/transactions';
import { SenderEnvelopeDTO } from 'support/types';

type DroppedSenderEnvelopeAlertProps = {
  senderEnvelope?: SenderEnvelopeDTO | null;
};

export function DroppedSenderEnvelopeAlert({ senderEnvelope }: DroppedSenderEnvelopeAlertProps) {
  const { t } = useTranslation();
  const isSenderEnvelopeDropped = checkSenderEnvelopeDropped(senderEnvelope);
  if (!isSenderEnvelopeDropped) {
    return null;
  }
  const droppedReasons = senderEnvelope?.droppedErrorReasons;

  return (
    <Alert withoutIcon fullWidth severity="neutral">
      <AlertSection severity="neutral" title={t('transactions:show.sections.errors.alert.messageDropped')}>
        {droppedReasons?.length
          ? droppedReasons.map((context) => <span key={context.reason}>{context.reason}</span>)
          : null}
      </AlertSection>
    </Alert>
  );
}
