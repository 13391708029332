import { BasePopupV2 } from 'components/Display/BasePopup/BasePopupV2';
import { OcrResult } from './types';
import { ProcessSpecificationDTO, RelationshipV2 } from 'support/types';
import { Extractor } from './components/Extractor/Extractor';

type DocumentExtractorProps = {
  senderProcessSpecification: ProcessSpecificationDTO;
  targetRelationship: RelationshipV2;
  onApplyOcrExtraction: (result: OcrResult) => void;
  setOpen: (open: boolean) => void;
  open: boolean;
  sourceDocumentId: string | undefined;
};

export const DocumentExtractor = ({
  open,
  setOpen,
  senderProcessSpecification,
  targetRelationship,
  onApplyOcrExtraction,
  sourceDocumentId,
}: DocumentExtractorProps) => {
  return (
    <BasePopupV2 setOpen={setOpen} open={open} width="xl" shouldGrowIndefinitely>
      <Extractor
        senderProcessSpecification={senderProcessSpecification}
        targetRelationship={targetRelationship}
        onApplyOcrExtraction={onApplyOcrExtraction}
        setOpen={setOpen}
        sourceDocumentId={sourceDocumentId}
      />
    </BasePopupV2>
  );
};
