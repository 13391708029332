import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type State = {
  overrideEnabled: boolean;
};

type Actions = {
  setOverrideEnabled: (overrideEnabled: boolean) => void;
  reset: () => void;
};

const initialState: State = {
  overrideEnabled: false,
};

export const useOCRStore = create(
  immer<State & Actions>((set) => ({
    ...initialState,

    setOverrideEnabled: (overrideEnabled: boolean) => {
      set((state) => {
        state.overrideEnabled = overrideEnabled;
      });
    },

    reset: () => {
      set(initialState);
    },
  })),
);
