import { Button } from 'components/Form/Button/Button';
import { ListTable } from 'components/ListTable/ListTable';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { routeToPage, routeToPriceCatalogListPage } from 'support/helpers/navigation/navigation';
import { DocumentType, PriceCatalogRoutes } from 'support/types';
import { CatalogPartnerListItem } from '../PriceCatalogsPage';
import { diffInMinutes, formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { TradePartnerNameAndLogo } from 'components/TradePartnerNameAndLogo';
import { FC, useCallback, useMemo, useState } from 'react';
import { RequestDocumentFromSenderModal } from '../modals/RequestDocumentFromSenderModal';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/Display/Tooltip/Tooltip';
import { lastPriceCatalogNotificationLogGroup } from 'pages/PriceCatalogs/helpers';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { CatalogPartnerListLastRequestElement } from './CatalogPartnerListLastRequestElement/CatalogPartnerListLastRequestElement';
import { components } from 'support/types/schema-portal';

type BulkActions<T> = Parameters<typeof ListTable<T>>[0]['bulkActions'];
export function CatalogPartnerList({
  data,
  isLoading,
  onClearFilters,
}: {
  data: Array<CatalogPartnerListItem>;
  isLoading: boolean;
  onClearFilters: () => void;
}) {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<Array<CatalogPartnerListItem>>([]);
  const [showRequestDocumentFromSenderModal, setRequestDocumentFromSenderModal] = useState(false);

  const tableHeaders = [
    { label: t('priceCatalogs:partnerList.tableHeaders.tradePartner'), key: 'name' },
    { label: t('priceCatalogs:partnerList.tableHeaders.catalogCount'), key: 'message count' },
    { label: t('priceCatalogs:partnerList.tableHeaders.lastReceivedDate'), key: 'last received date' },
    { label: t('priceCatalogs:partnerList.tableHeaders.lastRequestDate'), key: 'last request date' },
    { label: '', key: 'actions' },
  ];

  const bulkRequestDocumentFromSender = useCallback(
    (items: Array<CatalogPartnerListItem>): void => {
      setSelectedItems(items);
      setRequestDocumentFromSenderModal(true);
    },
    [setSelectedItems],
  );

  const priceCatalogRequestsInterval = 10;

  const buildRow = (item: CatalogPartnerListItem) => {
    return [
      {
        element: <TradePartnerNameAndLogo partner={item.partner} />,
        key: `name ${item.partner.id}`,
      },
      {
        element: item.messageCount,
        key: `message count ${item.partner.id}`,
      },
      {
        element: (
          <span className="tabular-nums">
            {item.lastMessageCreatedAt ? formatDayAndTime(item.lastMessageCreatedAt) : EMPTY_FIELD}
          </span>
        ),
        key: `last received date ${item.partner.id}`,
      },
      {
        element: (
          <span className="tabular-nums">
            <CatalogPartnerListLastRequestElement
              lastNotificationLogGroup={lastPriceCatalogNotificationLogGroup(item, priceCatalogRequestsInterval)}
            />
          </span>
        ),
        key: `last request date ${item.partner.id}`,
      },
      {
        element: (
          <CatalogPartnerActions
            item={item}
            priceCatalogRequestsInterval={priceCatalogRequestsInterval}
            onRequestCatalog={bulkRequestDocumentFromSender}
            lastPriceCatalogNotificationLogGroup={lastPriceCatalogNotificationLogGroup(
              item,
              priceCatalogRequestsInterval,
            )}
          />
        ),
        key: `actions ${item.partner.id}`,
      },
    ];
  };

  const bulkActions = useMemo(() => {
    const actions: NonNullable<BulkActions<CatalogPartnerListItem>>['actions'] = [];
    if (
      data.some((item) => {
        if (item.partnerIsSender) {
          return false;
        }

        const notificationLogGroup = lastPriceCatalogNotificationLogGroup(item, priceCatalogRequestsInterval);

        const latestNotificationLog =
          notificationLogGroup && notificationLogGroup.length > 0 ? notificationLogGroup[0] : null;

        return !(
          latestNotificationLog && diffInMinutes(latestNotificationLog.created_at) < priceCatalogRequestsInterval
        );
      })
    ) {
      actions.push({
        onClick: bulkRequestDocumentFromSender,
        icon: EnvelopeIcon,
        analyticsId: 'price-catalogs:bulk_request_catalog',
        type: 'button',
        label: t('priceCatalogs:partnerList.actions.requestCatalog'),
      });
    }

    if (actions.length === 0) {
      return undefined;
    }

    return {
      text: 'priceCatalogs:partnerList.bulk_selected',
      actions,
    };
  }, [bulkRequestDocumentFromSender, data, t]);

  return (
    <>
      <ListTable<CatalogPartnerListItem>
        headers={tableHeaders}
        data={data}
        isLoading={isLoading}
        rowBuilder={buildRow}
        rowClickPath={(item: CatalogPartnerListItem): string => routeToPriceCatalogListPage(item.catalogRelationshipId)}
        bulkActions={bulkActions}
        emptyState={
          <ListTable.EmptyState
            background="gray"
            cols={tableHeaders.length}
            cta={{
              children: t('common:table.withFilters.emptyState.cta'),
              onClick: onClearFilters,
              analyticsId: 'price-catalogs:clear-filters',
            }}
          >
            {t('common:table.withFilters.emptyState.title')}
          </ListTable.EmptyState>
        }
      />

      <RequestDocumentFromSenderModal
        catalogPartnerListItems={selectedItems}
        open={showRequestDocumentFromSenderModal}
        onComplete={() => setRequestDocumentFromSenderModal(false)}
      />
    </>
  );
}

const CatalogPartnerActions = ({
  item,
  priceCatalogRequestsInterval,
  onRequestCatalog,
  lastPriceCatalogNotificationLogGroup,
}: {
  item: CatalogPartnerListItem;
  priceCatalogRequestsInterval: number;
  onRequestCatalog: (items: Array<CatalogPartnerListItem>) => void;
  lastPriceCatalogNotificationLogGroup: Array<components['schemas']['NotificationLog']> | null;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const latestNotificationLog =
    lastPriceCatalogNotificationLogGroup && lastPriceCatalogNotificationLogGroup.length > 0
      ? lastPriceCatalogNotificationLogGroup[0]
      : null;

  const wasPriceCatalogRecentlyRequested = !!(
    latestNotificationLog && diffInMinutes(latestNotificationLog.created_at) < priceCatalogRequestsInterval
  );

  return (
    <div className="flex w-full justify-end">
      {item.partnerIsSender && item.senderUsesWebEDI && (
        <Button
          size="small"
          variant="secondary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(
              routeToPage(PriceCatalogRoutes.create, {
                relationshipId: item.catalogRelationshipId,
                messageType: DocumentType.productCatalog,
              }),
            );
          }}
          analyticsId="price-catalogs:create-navigate"
        >
          {t('priceCatalogs:partnerList.actions.newCatalog')}
        </Button>
      )}

      {!item.partnerIsSender && !wasPriceCatalogRecentlyRequested && (
        <RequestPriceCatalogButton
          wasPriceCatalogRecentlyRequested={wasPriceCatalogRecentlyRequested}
          onRequestCatalog={onRequestCatalog}
          item={item}
        />
      )}

      {!item.partnerIsSender && wasPriceCatalogRecentlyRequested && latestNotificationLog && (
        <Tooltip placement="top">
          <TooltipTrigger>
            <span>
              <RequestPriceCatalogButton
                wasPriceCatalogRecentlyRequested={wasPriceCatalogRecentlyRequested}
                onRequestCatalog={onRequestCatalog}
                item={item}
              />
            </span>
          </TooltipTrigger>
          <TooltipContent>
            {t('priceCatalogs:requestCatalogModal.actions.disabledTooltip', {
              date: formatDayAndTime(latestNotificationLog.created_at),
            })}
          </TooltipContent>
        </Tooltip>
      )}
    </div>
  );
};

const RequestPriceCatalogButton: FC<{
  wasPriceCatalogRecentlyRequested: boolean;
  onRequestCatalog: (items: Array<CatalogPartnerListItem>) => void;
  item: CatalogPartnerListItem;
}> = ({ wasPriceCatalogRecentlyRequested, onRequestCatalog, item }) => {
  const { t } = useTranslation();

  return (
    <Button
      size="small"
      variant="secondary"
      disabled={wasPriceCatalogRecentlyRequested}
      onClick={(e) => {
        e.preventDefault();
        onRequestCatalog([item]);
      }}
      analyticsId="price-catalogs:single-request-catalog"
    >
      {t('priceCatalogs:partnerList.actions.requestCatalog')}
    </Button>
  );
};
