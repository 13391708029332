import { useOrderSummary } from 'components/WebEDI/hooks/useOrderSummary';
import { useTranslation } from 'react-i18next';
import { formatTimestampToDay } from 'support/helpers/dateTime/dateTime';
import { WebEDISidebarTableContent } from '../../WebEDISidebarTableContent/WebEDISidebarTableContent';
import { formatCurrency } from 'support/helpers/currency/currency';
import { useDocumentCurrency } from 'components/WebEDI/helpers';

export const OrderSummary = () => {
  const { t } = useTranslation();
  const orderSummary = useOrderSummary();
  const currency = useDocumentCurrency();

  return (
    <WebEDISidebarTableContent
      items={[
        {
          label: t('webedi:documentSummary.order.totalQuantity.label'),
          value: Math.round(orderSummary.totalQuantity),
        },
        {
          label: t('webedi:documentSummary.order.lineCount.label'),
          value: Math.round(orderSummary.lineCount),
        },
        {
          label: t('webedi:documentSummary.order.deliveryDate.label'),
          value: formatTimestampToDay(orderSummary.deliveryDate) ?? '—',
        },
      ]}
      footer={
        orderSummary.totalValue > 0
          ? {
              label: t('webedi:documentSummary.order.footer'),
              value: formatCurrency(orderSummary.totalValue, currency),
            }
          : undefined
      }
    />
  );
};
