import { XMarkIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import useTimeout from 'use-timeout';

import { NotificationType, useNotificationStore } from 'stores/notifications/notifications';

type NotificationProps = {
  id: string;
  headline: string;
  description: string | undefined;
  type: NotificationType | undefined;
};

const AUTO_DISMISS_TIMEOUT = 10000;

export function Notification({ id, headline, description, type }: NotificationProps) {
  const { t } = useTranslation();
  const { removeNotification } = useNotificationStore();

  const onDismiss = () => {
    removeNotification(id);
  };

  useTimeout(onDismiss, AUTO_DISMISS_TIMEOUT);

  return (
    <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
      <div className="pointer-events-auto flex w-[28rem] rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="w-0 flex-1 p-4">
          <div className="flex items-start">
            {type && (
              <div className="shrink-0">
                {type === NotificationType.success ? (
                  <CheckCircleIcon className="size-6 text-procuros-green-500" aria-hidden="true" />
                ) : (
                  <XCircleIcon className="size-6 text-red-600" aria-hidden="true" />
                )}
              </div>
            )}

            <div className="ml-3 w-0 flex-1">
              <p className="text-sm font-medium text-gray-900">{headline}</p>
              <p className="mt-1 text-sm text-gray-500">{description}</p>
            </div>
          </div>
        </div>

        <div className="ml-4 flex shrink items-center pr-5">
          <button
            type="button"
            className="flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-procuros-green-400 focus-visible:ring-offset-2"
            onClick={onDismiss}
          >
            <span className="sr-only">{t('close')}</span>
            <XMarkIcon className="size-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}
