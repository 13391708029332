type LineItemWithIndex = {
  _internal: {
    index: number;
  };
};
export const orderLineItems = (newLineItems: Array<LineItemWithIndex>) => {
  return newLineItems.sort((a, b) => a._internal.index - b._internal.index);
};

export const _tempAddProcurosAdded = (formValues: Record<string, any>): Record<string, any> => {
  if (!('line_items' in formValues)) return formValues;

  const { line_items } = formValues;
  let hasPreviousProcurosAdded = false;
  return {
    ...formValues,
    line_items: line_items.map((lineItem: Record<string, any>) => {
      hasPreviousProcurosAdded = hasPreviousProcurosAdded || lineItem._internal?.procuros_added;
      return {
        ...lineItem,
        _internal: {
          ...lineItem._internal,
          procuros_added: hasPreviousProcurosAdded,
        },
      };
    }),
  };
};
