import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BookOpenIcon,
  Cog6ToothIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  HomeIcon,
  InboxIcon,
  LinkIcon,
  QuestionMarkCircleIcon,
  TagIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { ApplicationLogo } from 'components/Logo/ApplicationLogo/ApplicationLogo';
import {
  ConnectionsRoutes,
  DashboardRoutes,
  PricingConfigRoutes,
  SettingsRoutes,
  NetworkRoutes,
  TransactionsRoutes,
  PriceCatalogRoutes,
} from 'support/types';
import { SidebarProfile } from 'components/Page/SidebarProfile/SidebarProfile';
import { useConnectionsStats, useFailedTransactionsCount } from 'services/repositories/stats/stats';
import { SidebarItem } from './components/SidebarItem/SidebarItem';
import { getDocumentationLink } from 'support/helpers/const/const';
import { NavigationLink, NavigationLinkGroup } from '../types';
import { useLocalStorage } from 'usehooks-ts';
import { SidebarKeys } from 'support/types/localStorage';
import { useAnalytics } from 'support/helpers/analytics/analytics';
import { classNames } from 'support/helpers/generic/generic';
import { FEATURE_FLAGS, usePartnerFeatureFlag } from 'support/helpers/featureFlags/featureFlags';
import { ConnectionsFilterKey } from 'pages/Connections/tableConfig';
import { memo, useEffect } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { ENV } from 'services/environment/environment';

export const Sidebar = memo(() => {
  const { width = 0 } = useWindowSize();
  const { t, i18n } = useTranslation();
  const { track } = useAnalytics();
  const { data: failedTransactionsCountData } = useFailedTransactionsCount();
  const { enabled: priceCatalogsEnabled } = usePartnerFeatureFlag(FEATURE_FLAGS.TEMPORARY_PRICE_CATALOGS);
  const [expanded, setExpanded] = useLocalStorage(SidebarKeys.open, true);
  const { data: connectionsCountData } = useConnectionsStats({
    variables: {
      query: {
        filterValues: [
          {
            key: ConnectionsFilterKey.actionRequired,
            value: 'true',
          },
          {
            key: ConnectionsFilterKey.deactivated_partners,
            value: 'false',
          },
        ],
      },
    },
  });

  // Collapse sidebar on windows smaller than 1280px
  useEffect(() => {
    if (width > 0 && width < 1280) {
      setExpanded(false);
    }
  }, [width, setExpanded]);

  const toggleExpanded = () => {
    setExpanded((expanded) => !expanded);
  };

  const { data: failedTransactions } = useFailedTransactionsCount();

  const linkGroups: Array<NavigationLinkGroup> = [
    {
      links: [
        {
          href: DashboardRoutes.root,
          label: t('common:mainMenu.dashboard'),
          icon: <HomeIcon />,
        },
        {
          href: TransactionsRoutes.root,
          label: t('common:mainMenu.transactions'),
          icon: <InboxIcon />,
          ...(!!failedTransactionsCountData?.failedTransactions && {
            icon: <InboxIcon />,
            value: `${failedTransactionsCountData.failedTransactions}`,
          }),
          notifications: failedTransactions?.failedTransactions,
        },
        ...(priceCatalogsEnabled
          ? [
              {
                href: PriceCatalogRoutes.root,
                label: t('common:mainMenu.priceCatalogs'),
                icon: <BookOpenIcon />,
              },
            ]
          : [
              {
                href: PricingConfigRoutes.root,
                label: t('common:mainMenu.priceLists'),
                icon: <TagIcon />,
              },
            ]),
        {
          href: ConnectionsRoutes.root,
          label: t('common:mainMenu.connections'),
          icon: <LinkIcon />,
          notifications: connectionsCountData?.connectionsCount,
        },
        {
          href: NetworkRoutes.root,
          label: t('common:mainMenu.tradePartners'),
          icon: <GlobeAltIcon />,
        },
      ],
    },
    {
      links: [
        {
          href: SettingsRoutes.root,
          label: t('common:mainMenu.settings'),
          icon: <Cog6ToothIcon />,
        },
      ],
    },
    {
      links: [
        {
          href: getDocumentationLink(i18n.language),
          externalLink: true,
          onClick: () => track('documentation_opened'),
          label: t('common:mainMenu.help'),
          icon: <QuestionMarkCircleIcon />,
        },
        {
          href: ENV.VITE_PYLON_PORTAL_URL,
          label: t('common:mainMenu.emailUs.button'),
          icon: <EnvelopeIcon />,
          externalLink: true,
        },
      ],
    },
  ];

  return (
    <div className="flex h-full flex-col border-r border-gray-200">
      <div className={classNames('flex flex-col h-0 flex-1 bg-gray-50 flex-shrink-0', expanded ? 'w-64' : 'w-[72px]')}>
        <div className="flex flex-1 flex-col justify-between overflow-y-auto pb-4 pt-5">
          <div className={classNames('flex-1 flex flex-col', expanded ? ' px-6' : 'items-center')}>
            <div className="flex shrink-0 items-center pb-2">
              <ApplicationLogo
                variant="dark"
                withText={expanded}
                className={classNames('w-auto -mx-1.5', expanded ? 'h-8' : 'h-4')}
              />
            </div>

            {linkGroups.map((linkGroup, linkGroupIndex) => (
              <div key={linkGroupIndex}>
                <div className="flex flex-col space-y-1 pt-3">
                  {linkGroup.links.map((item: NavigationLink) => (
                    <SidebarItem
                      href={item.href}
                      key={item.href || item.label}
                      expanded={expanded}
                      onClick={item.onClick}
                      label={item.label}
                      icon={item.icon}
                      externalLink={item.externalLink}
                      notifications={item.notifications}
                    />
                  ))}
                </div>
                {linkGroupIndex + 1 !== linkGroups.length && (
                  <hr className="-mx-2 mt-4 border-t border-gray-200" aria-hidden="true" />
                )}
              </div>
            ))}
          </div>
          <div className={classNames('flex flex-shrink-0 px-4', expanded ? '' : 'justify-center')}>
            <SidebarItem
              onClick={toggleExpanded}
              icon={expanded ? <ArrowLeftIcon /> : <ArrowRightIcon />}
              label={expanded ? t('common:mainMenu.collapse') : t('common:mainMenu.expand')}
              expanded={expanded}
            ></SidebarItem>
          </div>
        </div>
        <SidebarProfile open={expanded} />
      </div>
    </div>
  );
});

Sidebar.displayName = 'Sidebar';
