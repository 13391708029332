import { Page } from 'components/Page/Page';
import { useNewFilters } from 'hooks/useFilters';
import { t } from 'i18next';
import { useInfinitePricingConfigs } from 'services/repositories/pricingConfigs/pricingConfigs';
import { routeToPage, routeToPricingConfigEditPage } from 'support/helpers/navigation/navigation';
import { PricingConfigList } from './PricingConfigList/PricingConfigList';
import { PricingConfigsFilterKey, getDefaultFilterConfig } from './tableConfig';
import { useConnectedPartners } from 'services/repositories/partners/partners';
import { MenuButton, MenuButtonOption } from 'components/MenuButton/MenuButton';
import { ArrowUpTrayIcon, PlusIcon, TagIcon } from '@heroicons/react/24/outline';
import { UploadPricingConfig } from '../../components/UploadPricingConfig/UploadPricingConfig';
import { useState, useMemo } from 'react';
import { useDebounce } from 'hooks/useDebounce';
import isEqual from 'lodash/isEqual';
import { addPartnersToFiltersConfig } from 'support/helpers/filters/filters';
import { Filters } from 'components/ListTable/Filters/Filters';
import { usePriceConfigsPartners } from 'hooks/usePriceConfigsPartners/usePriceConfigsPartners';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { useNavigate } from 'react-router-dom';
import { NetworkRoutes } from 'support/types';

export function PricingConfigsPage() {
  const navigate = useNavigate();
  const { data: partners } = useConnectedPartners();
  const [uploadPopupOpen, setUploadPopupOpen] = useState(false);
  const filterConfig = useMemo(
    () => addPartnersToFiltersConfig(getDefaultFilterConfig(), PricingConfigsFilterKey.partnerId, partners),
    [partners],
  );
  const { filterValues, filters, updateFilters, clearAllFilters, perPage } = useNewFilters(
    filterConfig,
    'pricingConfigs',
  );
  const hasFilters = filterValues && filterValues.length > 0;
  const debouncedFilterValues = useDebounce(filterValues, 150);
  const differentFilters = !isEqual(debouncedFilterValues, filterValues);
  const { data: partnersWithInvoices, isLoading: connectedPartnersLoading } = usePriceConfigsPartners();
  const hasTradePartnersWithInvoices = partnersWithInvoices?.length > 0;

  const {
    data: pricingConfigsData,
    isLoading: pricingConfigsLoading,
    isFetchingNextPage,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
  } = useInfinitePricingConfigs({
    variables: {
      query: { perPage, filterValues: debouncedFilterValues },
    },
  });

  return (
    <Page isLoading={connectedPartnersLoading || pricingConfigsLoading}>
      <Page.Head
        title={t('common:mainMenu.priceLists')}
        description={t('priceList:description')}
        rightContent={
          hasTradePartnersWithInvoices ? (
            <MenuButton text={t('priceList:list.new')} analyticsId="pricing-configs:new">
              <MenuButtonOption to={routeToPricingConfigEditPage()} Icon={PlusIcon} analyticsId="price-configs:edit">
                {t('priceList:list.create')}
              </MenuButtonOption>
              <MenuButtonOption
                onClick={() => setUploadPopupOpen(true)}
                Icon={ArrowUpTrayIcon}
                analyticsId="price-configs:upload"
              >
                {t('priceList:list.upload')}
              </MenuButtonOption>
            </MenuButton>
          ) : null
        }
      >
        {hasTradePartnersWithInvoices ? (
          <Filters
            propertyFilters={filters}
            onFiltersChange={updateFilters}
            filterValues={filterValues}
            clearAllFilters={clearAllFilters}
          />
        ) : null}
      </Page.Head>

      <Page.Section>
        {hasTradePartnersWithInvoices ? (
          <PricingConfigList
            pricingConfigsData={pricingConfigsData?.pages.flatMap((page) => page.items)}
            isLoading={pricingConfigsLoading || (differentFilters && !pricingConfigsData?.pages[0]?.items.length)}
            isFetchingNextPage={isFetchingNextPage}
            onNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            clearAllFilters={clearAllFilters}
            hasFilters={hasFilters}
          />
        ) : (
          <EmptyState
            title={t('priceList:missingInvoiceRelationship.title')}
            description={t('priceList:missingInvoiceRelationship.description')}
            ctaText={t('priceList:missingInvoiceRelationship.cta')}
            ctaOnClick={() => navigate(routeToPage(NetworkRoutes.root))}
            icon={<TagIcon />}
          />
        )}
        <UploadPricingConfig open={uploadPopupOpen} setOpen={setUploadPopupOpen} />
      </Page.Section>
    </Page>
  );
}
