type ApplicationEnvironment = 'local' | 'dev' | 'staging' | 'production';
const APP_ENV: ApplicationEnvironment = import.meta.env.VITE_ENV;

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT;

const PUBLIC_POSTHOG_HOST = import.meta.env.VITE_PUBLIC_POSTHOG_HOST;
const PUBLIC_POSTHOG_KEY = import.meta.env.VITE_PUBLIC_POSTHOG_KEY;

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
const MOCK_SERVER_BASE_URL = import.meta.env.VITE_MOCK_SERVER_BASE_URL;
const AS2_BASE_URL = import.meta.env.VITE_AS2_BASE_URL;
const AS2_MDN_RECEIVE_URL = import.meta.env.VITE_AS2_MDN_RECEIVE_URL;
const AS2_PROCUROS_ID = import.meta.env.VITE_AS2_PROCUROS_ID;

const DOCUPARSE_API_URL = import.meta.env.VITE_DOCUPARSE_API_URL;
const DOCUPARSE_API_KEY = import.meta.env.VITE_DOCUPARSE_API_KEY;

const VITE_PYLON_PORTAL_URL = import.meta.env.VITE_PYLON_PORTAL_URL;

const FEATURE_FLAG: Record<string, boolean | undefined> = {};

const SENTRY_RELEASE = import.meta.env.VITE_SENTRY_RELEASE;

const FLATFILE_PUBLISHABLE_KEY = import.meta.env.VITE_FLATFILE_PUBLISHABLE_KEY;

const ADMIN_UI_URL = import.meta.env.VITE_ADMIN_UI_URL;

export const ENV = {
  APP_ENV,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  PUBLIC_POSTHOG_HOST,
  PUBLIC_POSTHOG_KEY,
  API_BASE_URL,
  AS2_BASE_URL,
  AS2_MDN_RECEIVE_URL,
  AS2_PROCUROS_ID,
  FEATURE_FLAG,
  SENTRY_RELEASE,
  FLATFILE_PUBLISHABLE_KEY,
  MOCK_SERVER_BASE_URL,
  DOCUPARSE_API_URL,
  DOCUPARSE_API_KEY,
  VITE_PYLON_PORTAL_URL,
  ADMIN_UI_URL,
};
