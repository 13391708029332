import { InformationCircleIcon, LockClosedIcon, UserCircleIcon, UsersIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { SettingsRoutes } from 'support/types';
import { NavigationLinkGroup } from '../../../components/Page/types';
import { SecondaryNavigation } from '../../../components/Page/SecondaryNavigation/SecondaryNavigation';

export function SettingsSidebar() {
  const { t } = useTranslation();
  const linkGroups: Array<NavigationLinkGroup> = [
    {
      title: t('settings:sidebar.companyWorkspace'),
      links: [
        {
          href: SettingsRoutes.companyData,
          label: t('settings:sidebar.companyData'),
          icon: <InformationCircleIcon />,
        },
        {
          href: SettingsRoutes.teamMembers,
          label: t('settings:sidebar.teamMembers'),
          icon: <UsersIcon />,
        },
      ],
    },
    {
      title: t('settings:sidebar.account'),
      links: [
        {
          href: SettingsRoutes.profileAndPreferences,
          label: t('settings:sidebar.profileAndPreferences'),
          icon: <UserCircleIcon />,
        },
        {
          href: SettingsRoutes.password,
          label: t('settings:sidebar.password'),
          icon: <LockClosedIcon />,
        },
      ],
    },
  ];

  return <SecondaryNavigation linkGroups={linkGroups} />;
}
