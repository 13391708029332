import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

type PriceCatalogSummaryValues = {
  lineCount: number;
  creationDate?: number;
};

export const usePriceCatalogSummary = (): PriceCatalogSummaryValues => {
  const [lineItems, catalogDate] = useWatch({ name: ['line_items', 'info.0.catalog_date'] });

  return useMemo(
    () => ({
      lineCount: lineItems?.length ?? 0,
      creationDate: catalogDate,
    }),
    [lineItems?.length, catalogDate],
  );
};
