import { useParams } from 'react-router-dom';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { Page } from 'components/Page/Page';
import { MessageHeader } from './MessageHeader/MessageHeader';
import { useMessageWithProcessSpecification } from 'services/repositories/messages/messages';
import { WebEDISidebar } from 'components/WebEDI/WebEDISidebar/WebEDISidebar';
import { DroppedMessageAlert } from '../DroppedMessageAlert/DroppedMessageAlert';
import { DisplayModes } from 'components/DataTable/Types';
import { FormProvider } from 'react-hook-form';
import { Form } from 'components/Form/Form';
import { useEffect, useLayoutEffect, useState } from 'react';
import { IssueResolverType, MessageV2DTO, PartnerDTO, ProcessSpecificationDTO } from 'support/types';
import { DocumentSections } from 'components/WebEDI/DocumentSections/DocumentSections';
import { UnkownTypePlaceholder } from './UnkownTypePlaceholder';
import { DocumentSummary } from 'components/WebEDI/DocumentSummary/DocumentSummary';
import { hasDocumentSummary } from 'components/WebEDI/DocumentSummary/helpers';
import { useFlattenDataAndUIConfig } from 'hooks/useFlattenDataAndUIConfig';
import { cleanStore, setDestinationProcess, setProcessSpecificationFields, setCanonical } from 'stores/webedi/webedi';
import { terminateDataChecksWorker } from 'services/datachecks/datachecks';
import { useTransactionActionRequired } from 'hooks/useTransactionActionRequired';
import { WebEDIMessageErrorBanner } from 'components/WebEDI/WebEDIMessageErrorBanner/WebEDIMessageErrorBanner';
import { useCurrentUser } from 'services/repositories/user/user';
import { useAcknowledgement } from './hooks/useAcknowledgement';
import { hasProofOfDelivery, ProofOfDelivery } from 'components/WebEDI/ProofOfDelivery/ProofOfDelivery';
import { useMessageViewForm } from './hooks/useMessageViewForm';
import { useIsAdminOCRTest } from './hooks/useIsAdminOCRTest';

const mode = DisplayModes.view;

export function MessageViewPage() {
  const { id: messageId } = useParams<{ id: string }>();
  const { data: currentPartner } = useCurrentPartner();
  const { data: currentUser } = useCurrentUser();
  const [hasLoadingError, setHasLoadingError] = useState(false);

  const {
    data: message,
    isError,
    isLoading,
  } = useMessageWithProcessSpecification({
    variables: {
      messageId,
    },
    refetchInterval: hasLoadingError ? undefined : 1000 * 10,
  });

  useIsAdminOCRTest(messageId);

  useEffect(() => {
    //Stop refetching if there's an error
    setHasLoadingError(isError);
  }, [isError]);

  useAcknowledgement(message?.data, currentPartner, currentUser, message?.data?.type);

  useLayoutEffect(() => {
    cleanStore();
    return () => {
      cleanStore();
      terminateDataChecksWorker();
    };
  }, []);

  useEffect(() => {
    if (message?.data?.canonical) {
      setCanonical(message?.data?.canonical);
    }
  }, [message]);

  useEffect(() => {
    if (message?.process) {
      setDestinationProcess(message?.process);
    }
  }, [message]);

  useEffect(() => {
    setProcessSpecificationFields(message?.fields);
  }, [message]);

  if (!messageId) return;

  if (isError) {
    return <UnkownTypePlaceholder messageId={messageId} />;
  }

  if (isLoading) {
    /*
     * We don't return MessageViewPageUI so we can start the form with the correct initial data
     * This way we avoid rendering "0 line items" in the UI when the data is loaded but not yet debounced
     */
    return <Page isLoading={isLoading}>{null}</Page>;
  }

  return (
    <MessageViewPageUI
      message={message?.data}
      relatedTransactions={message?.related_messages}
      currentPartner={currentPartner}
      processSpecFields={message?.fields}
    />
  );
}

type MessageViewPageV2UIProps = {
  message: MessageV2DTO | undefined;
  relatedTransactions: Array<MessageV2DTO> | undefined;
  currentPartner: PartnerDTO | undefined;
  processSpecFields: ProcessSpecificationDTO['fields'] | undefined;
};
export const MessageViewPageUI = ({
  message,
  relatedTransactions,
  currentPartner,
  processSpecFields,
}: MessageViewPageV2UIProps) => {
  const { data: flattenData, uiConfig } = useFlattenDataAndUIConfig(processSpecFields, message?.canonical);

  const [issueResolver, issueResolverLabel]: [IssueResolverType, string] = useTransactionActionRequired()(
    message?.issue_resolver_id,
  );

  const { formMethods, isLoading } = useMessageViewForm({ flattenData, message, processSpecFields });

  return (
    <Page>
      <Form id="webedi-document">
        <FormProvider {...formMethods}>
          <Page.Head>
            {!!message && (
              <MessageHeader
                mode={mode}
                message={message}
                relatedTransactions={relatedTransactions}
                currentPartner={currentPartner}
                issueResolverLabel={issueResolverLabel}
              />
            )}
          </Page.Head>
          <Page.Section
            isLoading={isLoading}
            topBanner={
              <>
                <DroppedMessageAlert message={message} />
                {message?.blocking_error ? (
                  <WebEDIMessageErrorBanner
                    messageId={message.id}
                    mode={mode}
                    blockingError={message.blocking_error}
                    issueResolver={issueResolver}
                  />
                ) : null}
              </>
            }
            rightAside={
              mode === DisplayModes.view ? (
                <WebEDISidebar
                  document={message}
                  relatedTransactions={relatedTransactions}
                  currentPartner={currentPartner}
                >
                  {hasDocumentSummary(message?.type) ? <DocumentSummary documentType={message?.type} /> : null}
                  {hasProofOfDelivery(message) ? (
                    <ProofOfDelivery receiverEnvelope={message.receiver_envelope} />
                  ) : null}
                </WebEDISidebar>
              ) : null
            }
          >
            {message ? (
              <DocumentSections
                mode={mode}
                uiConfig={uiConfig}
                documentType={message.type}
                variables={message.receiver_partner ? { receiverPartnerName: message.receiver_partner?.name } : {}}
              />
            ) : null}
          </Page.Section>
        </FormProvider>
      </Form>
    </Page>
  );
};
