import { ENV } from 'services/environment/environment';
import { MergeStats, UnmatchedLineItem, UpdateExtractionResult } from './types';
import { logError } from 'services/logging/logging';
import { DocumentType } from 'support/types';

export const recordExtractionQuality = async (documentHash: string, extractionResultUpdate: UpdateExtractionResult) => {
  try {
    await fetch(`${ENV.DOCUPARSE_API_URL}api/v1/extraction_quality/${documentHash}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ENV.DOCUPARSE_API_KEY}`,
      },
      body: JSON.stringify(extractionResultUpdate),
    });
  } catch (e: unknown) {
    logError(e);
  }
};

const MERGE_STATS_CONFIG: Record<
  DocumentType.shippingNotice | DocumentType.invoice,
  {
    matched: (lineItem: any) => boolean;
    unmatched: (lineItem: any) => boolean;
    missing: (lineItem: any) => boolean;
  }
> = {
  [DocumentType.shippingNotice]: {
    matched: (_lineItem: any) => {
      return true;
    },
    unmatched: (_lineItem: any) => {
      return true;
    },
    missing: (lineItem: any) => {
      return lineItem._ocr_internal?.original?.shipped_quantity !== 0;
    },
  },
  [DocumentType.invoice]: {
    matched: (_lineItem: any) => {
      return true;
    },
    unmatched: (_lineItem: any) => {
      return true;
    },
    missing: (lineItem: any) => {
      return lineItem._ocr_internal?.original?.ordered_quantity !== 0;
    },
  },
};

const DEFAULT_MERGE_STATS_CONFIG = {
  matched: (_lineItem: any) => {
    return true;
  },
  unmatched: (_lineItem: any) => {
    return true;
  },
  missing: (_lineItem: any) => {
    return true;
  },
};
export const calculateMergeStats = (
  mergedData: any,
  unmatchedLineItems: Array<UnmatchedLineItem>,
  documentType: DocumentType,
): MergeStats => {
  if (!mergedData) {
    return { matched: 0, added: 0, missing: 0 };
  }

  let extractionStats = { matched: 0, unmatched: 0, missing: 0 };
  const matchingFunctions =
    MERGE_STATS_CONFIG[documentType as keyof typeof MERGE_STATS_CONFIG] || DEFAULT_MERGE_STATS_CONFIG;

  // Helper function to count line items by condition
  const countLineItems = (lineItems: Array<any>, condition: (lineItem: any) => boolean): number => {
    return lineItems.filter(condition).length;
  };

  // Common filtering conditions
  const isMatched = (lineItem: any) => lineItem._ocr_internal?.matched === true && matchingFunctions.matched(lineItem);
  const isUnmatched = (lineItem: any) =>
    lineItem._ocr_internal?.matched === false &&
    lineItem._ocr_internal?.extracted === true &&
    matchingFunctions.unmatched(lineItem);
  const isMissing = (lineItem: any) =>
    lineItem._ocr_internal?.matched === false &&
    lineItem._ocr_internal?.extracted === false &&
    matchingFunctions.missing(lineItem);

  // Calculate stats based on document type
  if (documentType === DocumentType.shippingNotice) {
    // For shipping notices, line items are within transport units
    extractionStats = mergedData.transport_units?.reduce(
      (acc: any, transportUnit: any) => {
        const lineItems = transportUnit.line_items || [];
        return {
          matched: acc.matched + countLineItems(lineItems, isMatched),
          unmatched: acc.unmatched + countLineItems(lineItems, isUnmatched),
          missing: acc.missing + countLineItems(lineItems, isMissing),
        };
      },
      { matched: 0, unmatched: 0, missing: 0 },
    ) || { matched: 0, unmatched: 0, missing: 0 };
  } else {
    // For other document types, line items are at the root level
    const lineItems = mergedData.line_items || [];
    extractionStats.matched = countLineItems(lineItems, isMatched);
    extractionStats.unmatched = countLineItems(lineItems, isUnmatched);
    extractionStats.missing = countLineItems(lineItems, isMissing);
  }

  const addedUnmatchedLineItems = unmatchedLineItems.filter((unmatchedLineItem) => unmatchedLineItem.keep === 'true');

  return {
    matched: extractionStats.matched,
    added: addedUnmatchedLineItems.length,
    missing: extractionStats.missing,
  };
};
