import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/Display/Tooltip/Tooltip';
import { RelationshipStateBadge } from 'components/RelationshipStateBadge/RelationshipStateBadge';
import { Translate } from 'components/Translate/Translate';
import { useTranslation } from 'react-i18next';
import { logError } from 'services/logging/logging';
import { EMPTY_FIELD } from 'support/helpers/const/const';
import { formatDayAndTime } from 'support/helpers/dateTime/dateTime';
import { GenericError } from 'support/helpers/errors/errors';
import { RelationshipV2 } from 'support/types';

export const RelationshipTimelineActivity = ({
  activity,
  relationship,
}: {
  activity: Required<RelationshipV2>['timeline_events'][number];
  relationship: RelationshipV2;
}) => {
  const { t } = useTranslation();

  const partnerWhoDidTheAction =
    activity.meta?.partner_id === relationship.sender_partner?.id
      ? relationship.sender_partner
      : relationship.receiver_partner;

  switch (activity.type) {
    case 'RELATIONSHIP_REQUESTED':
      return (
        <Translate
          i18nKey="connections:relationshipDetails.timeline.RELATIONSHIP_REQUESTED"
          values={{ partnerName: partnerWhoDidTheAction?.name }}
        />
      );
    case 'PARTNER_ONBOARDING_PENDING':
      return (
        <Translate
          i18nKey="connections:relationshipDetails.timeline.PARTNER_ONBOARDING_PENDING"
          values={{ partnerName: partnerWhoDidTheAction?.name }}
        />
      );
    case 'PARTNER_IS_READY':
      return (
        <Translate
          i18nKey="connections:relationshipDetails.timeline.PARTNER_IS_READY"
          values={{ partnerName: partnerWhoDidTheAction?.name }}
        />
      );
    case 'RELATIONSHIP_GO_LIVE_DATE_SCHEDULED': {
      const goLiveDate = typeof activity.meta?.go_live_date === 'string' ? activity.meta.go_live_date : undefined;
      return (
        <div className="flex gap-2">
          <Translate
            i18nKey="connections:relationshipDetails.timeline.RELATIONSHIP_GO_LIVE_DATE_SCHEDULED"
            values={{
              date: goLiveDate ? formatDayAndTime(goLiveDate) : EMPTY_FIELD,
            }}
            components={{
              tooltip:
                goLiveDate && new Date(goLiveDate) < new Date(activity.created_at) ? (
                  <Tooltip>
                    <TooltipTrigger>
                      <InformationCircleIcon className="size-5 text-gray-400" />
                    </TooltipTrigger>
                    <TooltipContent>
                      {t('connections:relationshipDetails.timeline.prematureGoLiveTooltip')}
                    </TooltipContent>
                  </Tooltip>
                ) : (
                  <></>
                ),
            }}
          />
        </div>
      );
    }
    case 'RELATIONSHIP_LIVE_ORDER_NEEDED':
      return <Translate i18nKey="connections:relationshipDetails.timeline.RELATIONSHIP_LIVE_ORDER_NEEDED" />;
    case 'RELATIONSHIP_TEST_MESSAGE_REQUESTED':
      return (
        <Translate
          i18nKey="connections:relationshipDetails.timeline.RELATIONSHIP_TEST_MESSAGE_REQUESTED"
          values={{ partnerName: relationship.sender_partner?.name }}
        />
      );
    case 'RELATIONSHIP_TEST_MESSAGE_REQUESTED_REMINDER':
      return (
        <Translate
          i18nKey="connections:relationshipDetails.timeline.RELATIONSHIP_TEST_MESSAGE_REQUESTED_REMINDER"
          values={{ partnerName: relationship.sender_partner?.name }}
        />
      );
    case 'RELATIONSHIP_TEST_MESSAGE_PENDING_REVIEW':
      return (
        <Translate
          i18nKey="connections:relationshipDetails.timeline.RELATIONSHIP_TEST_MESSAGE_PENDING_REVIEW"
          values={{ partnerName: relationship.receiver_partner?.name }}
        />
      );
    case 'RELATIONSHIP_TEST_MESSAGE_PENDING_REVIEW_REMINDER':
      return (
        <Translate
          i18nKey="connections:relationshipDetails.timeline.RELATIONSHIP_TEST_MESSAGE_PENDING_REVIEW_REMINDER"
          values={{ partnerName: relationship.receiver_partner?.name }}
        />
      );
    case 'RELATIONSHIP_TEST_MESSAGE_REJECTED':
      return (
        <Translate
          i18nKey="connections:relationshipDetails.timeline.RELATIONSHIP_TEST_MESSAGE_REJECTED"
          values={{ partnerName: relationship.receiver_partner?.name }}
        />
      );

    case 'RELATIONSHIP_TEST_MESSAGE_APPROVED':
      return (
        <Translate
          i18nKey="connections:relationshipDetails.timeline.RELATIONSHIP_TEST_MESSAGE_APPROVED"
          values={{ partnerName: relationship.receiver_partner?.name }}
        />
      );
    case 'RELATIONSHIP_IS_READY': {
      return (
        <Translate
          i18nKey="connections:relationshipDetails.timeline.RELATIONSHIP_IS_READY"
          values={{
            documentType: t(`common:messageTypes.singular.${relationship.message_type}`),
          }}
          components={{ status: <RelationshipStateBadge status="ACTIVE" sender requester /> }}
        />
      );
    }
    case 'RELATIONSHIP_DEACTIVATED':
      return (
        <Translate
          i18nKey="connections:relationshipDetails.timeline.RELATIONSHIP_DEACTIVATED"
          components={{ status: <RelationshipStateBadge status="DEACTIVATED" sender requester /> }}
        />
      );
    case 'RELATIONSHIP_BLOCKED':
      return (
        <div>
          <Translate
            i18nKey="connections:relationshipDetails.timeline.RELATIONSHIP_BLOCKED"
            components={{
              status: <RelationshipStateBadge status="BLOCKED" sender requester />,
            }}
          />
        </div>
      );
    case 'RELATIONSHIP_FIRST_LIVE_MESSAGE_RECEIVED':
      return (
        <Translate
          i18nKey="connections:relationshipDetails.timeline.RELATIONSHIP_FIRST_LIVE_MESSAGE_RECEIVED"
          values={{ documentType: t(`common:messageTypes.singular.${relationship.message_type}`) }}
          components={{
            status: <RelationshipStateBadge status="LIVE" sender requester />,
          }}
        />
      );

    case 'INTEGRATION_IS_READY':
      return (
        <Translate
          i18nKey="connections:relationshipDetails.timeline.INTEGRATION_IS_READY"
          values={{
            partnerName: partnerWhoDidTheAction?.name,
            connector: t(`integrations:connector.${activity.meta?.connector as string}.name`),
          }}
        />
      );

    case 'RELATIONSHIP_BACKCHANNEL_IS_NOW_POSSIBLE':
      return <Translate i18nKey="connections:relationshipDetails.timeline.RELATIONSHIP_BACKCHANNEL_IS_NOW_POSSIBLE" />;

    default:
      //in production we should never reach this case
      logError(
        new GenericError('Unknown activity key on relationship timeline', {
          relationshipId: relationship.id,
          activityLog: relationship.timeline_events,
        }),
      );
      return '';
  }
};

/**
 * i18n
 * t('common:messageTypes.singular.QUOTE_REQUEST')
 * t('common:messageTypes.singular.QUOTE')
 * t('common:messageTypes.singular.ORDER')
 * t('common:messageTypes.singular.ORDER_CHANGE')
 * t('common:messageTypes.singular.ORDER_RESPONSE')
 * t('common:messageTypes.singular.DISPATCH_INSTRUCTION')
 * t('common:messageTypes.singular.DISPATCH_INSTRUCTION_RESPONSE')
 * t('common:messageTypes.singular.SHIPPING_NOTICE')
 * t('common:messageTypes.singular.INVOICE')
 * t('common:messageTypes.singular.CREDIT_NOTE')
 * t('common:messageTypes.singular.PRODUCT_CATALOG')
 * t('common:messageTypes.singular.RECEIVAL_NOTICE')
 * t('common:messageTypes.singular.REMITTANCE_ADVICE')
 * t('common:messageTypes.singular.UNKNOWN')
 * t('common:messageTypes.singular.ACKNOWLEDGEMENT')
 *
 * t('integrations:connector.API_V2.name')
 * t('integrations:connector.AS2.name')
 * t('integrations:connector.EMAIL.name')
 * t('integrations:connector.HAUFE.name')
 * t('integrations:connector.MICROSOFT_BUSINESS_CENTRAL.name')
 * t('integrations:connector.NONE.name')
 * t('integrations:connector.NONE.tooltip')
 * t('integrations:connector.ODOO.name')
 * t('integrations:connector.OTHER.name')
 * t('integrations:connector.SFTP.name')
 * t('integrations:connector.WEB_EDI.name')
 * t('integrations:connector.WECLAPP.name')
 * t('integrations:connector.XENTRAL_V2.name')
 *
 */
