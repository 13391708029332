import { Button } from 'components/Form/Button';
import { DocumentExtractor } from 'components/WebEDI/DocumentExtractor/DocumentExtractor';
import { OcrResult } from 'components/WebEDI/DocumentExtractor/types';
import { t } from 'i18next';
import { FC, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSenderProcessSpecification } from 'services/repositories/relationships/relationships';
import { setInitialCanonical } from 'stores/webedi/webedi';
import { useIsBetaOCR, useShouldShowOcr } from 'support/helpers/ocr/ocr';
import { DocumentType, RelationshipV2 } from 'support/types';

type OCRButtonProps = {
  setOcrResult: (ocrResult: OcrResult) => void;
  targetRelationship: RelationshipV2 | undefined;
  sourceMessageId: string | undefined;
  documentType: DocumentType;
  disabled: boolean;
};

export const OCRButton: FC<OCRButtonProps> = ({
  setOcrResult,
  targetRelationship,
  sourceMessageId,
  documentType,
  disabled,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [uploadModalOpen, setUploadModalOpen] = useState(!!searchParams.get('showUpload'));

  const setUploadModalState = (status: boolean) => {
    setUploadModalOpen(status);
    // OCR Button will be remounted because the setIniticalCanonical sets Page to loading state
    // Page loading state removes all content of the page
    // We are removing the showUpload query param from the URL so it doesn't open the modal again
    setSearchParams((prev) => {
      prev.delete('showUpload');
      return prev;
    });
  };
  const { data: senderProcessSpecification } = useSenderProcessSpecification({
    variables: { relationshipId: targetRelationship?.id, sourceMessageId },
  });

  const onApplyOcrExtraction = useCallback(
    (ocrResult: OcrResult) => {
      setOcrResult(ocrResult);
      if (ocrResult.data) {
        setInitialCanonical(ocrResult.data);
      }
    },
    [setOcrResult],
  );

  const shouldShowOCR = useShouldShowOcr(documentType);
  const { isLoading: isLoadingOCRBeta, isBeta } = useIsBetaOCR({
    targetRelationshipId: targetRelationship?.id,
    sourceMessageId,
  });

  if (isBeta) {
    return null;
  }

  return (
    <>
      {shouldShowOCR && !isLoadingOCRBeta && (
        <Button
          variant="secondary"
          disabled={disabled}
          onClick={() => setUploadModalState(true)}
          analyticsId="webedi:new_document_upload"
          className="flex justify-between gap-2"
        >
          {t('webedi:createDocument.uploadDocument.label')}
        </Button>
      )}
      {targetRelationship && senderProcessSpecification ? (
        <DocumentExtractor
          open={uploadModalOpen && shouldShowOCR}
          setOpen={setUploadModalState}
          senderProcessSpecification={senderProcessSpecification}
          targetRelationship={targetRelationship}
          sourceDocumentId={sourceMessageId}
          onApplyOcrExtraction={onApplyOcrExtraction}
        />
      ) : null}
    </>
  );
};
