import { useNotificationStore } from 'stores/notifications/notifications';
import { Notification } from 'components/Notification/Notification';
import { FloatingPortal } from '@floating-ui/react';

export function NotificationList() {
  const { notifications } = useNotificationStore();

  if (notifications.length === 0) {
    return null;
  }

  return (
    <FloatingPortal>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed bottom-0 right-0 z-50 flex w-fit flex-col gap-4 px-4 py-6 sm:items-start sm:p-6"
      >
        {notifications.map((notification) => {
          return (
            <Notification
              key={notification.id}
              id={notification.id}
              headline={notification.title}
              description={notification.subtitle}
              type={notification.type}
            />
          );
        })}
      </div>
    </FloatingPortal>
  );
}
