import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

type OrderResponseSummaryValues = {
  totalQuantity: number;
  lineCount: number;
  deliveryDate?: number;
};

const calculateLineItemsQuantity = (lineItems: Array<any> | undefined): number => {
  return (
    lineItems?.reduce((acc, item) => {
      return acc + (item.confirmed_quantity ?? 0);
    }, 0) || 0
  );
};

export const useOrderResponseSummary = (): OrderResponseSummaryValues => {
  const [lineItems, confirmed_delivery_date] = useWatch({ name: ['line_items', 'info.0.confirmed_delivery_date'] });

  return useMemo(
    () => ({
      totalQuantity: calculateLineItemsQuantity(lineItems),
      lineCount: lineItems?.length ?? 0,
      deliveryDate: confirmed_delivery_date,
    }),
    [lineItems, confirmed_delivery_date],
  );
};
