import { useDataTableContext } from 'components/DataTable/contexts/DataTableContext';
import { ColumnTypesInternal } from 'components/DataTable/Types';
import { useEmptyStructure } from 'hooks/useObjectStructure';
import ImportModal, { DataRow } from './components/ImportModal';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { unflattenObject, withoutNulls } from 'support/helpers/generic/generic';
import { useWebEDIStore } from 'stores/webedi/webedi';
import {
  fromProcessSpecFieldsToFieldDefinition,
  keepFieldsWithPrefix,
  removeFromFieldsKey,
  removeNestedFieldsWithPrefix,
  updateModificationGroups,
  updatePackagingUnits,
} from './helpers';

type LineItemsImporterProps = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  importMode: ImportMode;
};

export type ImportMode = 'REPLACE' | 'APPEND';

export const LineItemsImporter = ({ isOpen, setOpen, importMode }: LineItemsImporterProps) => {
  const processSpecificationFields = useWebEDIStore((state) => state.processSpecificationFields);
  const { t } = useTranslation();
  const { fields, append, setValue } = useDataTableContext();
  const emptyLineItem = useEmptyStructure(fields);
  const importFields = useMemo(() => {
    if (processSpecificationFields) {
      const fieldsToUse = updatePackagingUnits(
        updateModificationGroups(
          removeNestedFieldsWithPrefix(keepFieldsWithPrefix(processSpecificationFields, 'line_items'), 'line_items'),
        ),
      );

      const convertedFields = fromProcessSpecFieldsToFieldDefinition({
        processSpecFields: fieldsToUse,
        uniqueFields: ['line_items.*.item.identifiers.gtin'],
        excludedFields: ['line_items.*.catalog_line_identifier', 'line_items.*.item.type'],
      });
      return removeFromFieldsKey(convertedFields, 'line_items.*.');
    }
    return undefined;
  }, [processSpecificationFields]);

  const onImport = (rows: Array<DataRow>) => {
    const skipRowNumber = importMode === 'REPLACE' ? 0 : fields.length;

    const newItems = rows.map((item, itemIndex) => {
      const newValue = unflattenObject(item);

      handleNewItemModification(newValue, 'charges');
      handleNewItemModification(newValue, 'allowances');

      return {
        ...emptyLineItem,
        ...newValue,
        catalog_line_identifier: `${skipRowNumber + itemIndex + 1}`,
        _internal: {
          [ColumnTypesInternal.procuros_added]: true,
        },
        item: {
          ...withoutNulls(newValue.item),
          type: 'PRODUCT',
        },
      };
    });

    if (importMode === 'REPLACE') {
      //set value is a one time set, no need to remove and then append
      // we are debouncing the fields, so remove and then append can lead to weird situations
      setValue(undefined, newItems);
    } else {
      append(newItems);
    }
  };

  if (!importFields) return null;

  return (
    <ImportModal
      workbookName={t('priceCatalogs:importModal.workbook.name')}
      workbookDescription={t('priceCatalogs:importModal.workbook.description')}
      sheetName={t('priceCatalogs:importModal.sheet.name')}
      sheetDescription={t('priceCatalogs:importModal.sheet.description')}
      importActionLabel={t('priceCatalogs:importModal.importAction.label')}
      importActionDescription={t('priceCatalogs:importModal.importAction.description')}
      fields={importFields}
      open={isOpen}
      setOpen={setOpen}
      onImport={onImport}
    />
  );
};

/**
 * If item contains a charge or allowance check if it is a relative or absolute one.
 * if it is relative, set the percentage to the amount coming from the row.item, otherwise set the amount to the row amount
 * We only have an amount column in the import modal, so we need to check if the charge or allowance is relative or absolute
 * Important: It updates the newItem object directly
 */
const handleNewItemModification = (
  newItem: Record<string, any>,
  modificationType: 'charges' | 'allowances',
): Record<string, any> => {
  if (newItem.modification_groups?.[0]?.[modificationType]?.[0]?.type === 'RELATIVE') {
    newItem.modification_groups[0][modificationType][0].percentage =
      newItem.modification_groups[0][modificationType][0].amount;

    delete newItem.modification_groups[0][modificationType][0].amount;
  }

  return newItem;
};
