import { useClose, DialogTitle, Description } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Alert, AlertSection } from 'components/Display/Alert';
import { Button } from 'components/Form/Button';
import { FileInput } from 'components/Form/FileInput/FileInput';
import { UploadLoader } from 'components/Form/FileInput/Loaders/UploadLoader/UploadLoader';
import { Translate } from 'components/Translate/Translate';
import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ENV } from 'services/environment/environment';
import { logError } from 'services/logging/logging';
import { useCurrentUser } from 'services/repositories/user/user';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { useAnalytics } from 'support/helpers/analytics/analytics';
import { GenericError } from 'support/helpers/errors/errors';
import { ProcessSpecificationDTO, RelationshipV2 } from 'support/types';

type UploadStageProps = {
  senderSpec: Pick<ProcessSpecificationDTO, 'fields'>;
  receiverSpec: Pick<ProcessSpecificationDTO, 'fields'>;
  targetRelationship: RelationshipV2;
  documentType?: string | null;
  sourceDocumentId: string | undefined;
  initialData?: any;
  onExtracted: (data: any, documentHash: string) => void;
};

type ExtractionError = {
  title: string;
  message: string;
};

export const UploadStage = ({
  senderSpec,
  receiverSpec,
  targetRelationship,
  documentType,
  initialData,
  onExtracted,
  sourceDocumentId,
}: UploadStageProps) => {
  const { track } = useAnalytics();
  const { t } = useTranslation();
  const close = useClose();
  const { getSessionId } = useAnalytics();
  const [isExtracting, setIsExtracting] = useState(false);
  const [extractionError, setExtractionError] = useState<ExtractionError>();
  const { data: currentUser } = useCurrentUser();
  const { data: currentPartner } = useCurrentPartner();

  const loader = useMemo(() => {
    track('webedi:document_upload_started');
    return UploadLoader({
      url: `${ENV.DOCUPARSE_API_URL}api/v1/upload`,
      bearerToken: ENV.DOCUPARSE_API_KEY,
      body: {
        sender_partner_id: currentPartner?.id,
        document_type: documentType,
        source_document_id: sourceDocumentId,
      },
    });
  }, [track, currentPartner, documentType, sourceDocumentId]);

  const onError = useCallback(
    (error: any) => {
      setExtractionError({
        title: t('webedi:documentExtractor.upload.error.title'),
        message: error.message,
      });
      console.error(error);
    },
    [t],
  );

  const onLoadFile = useCallback(
    async (data: any) => {
      setIsExtracting(true);
      setExtractionError(undefined);
      track('webedi:document_upload_successful');

      fetch(`${ENV.DOCUPARSE_API_URL}api/v1/extract-from-spec`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ENV.DOCUPARSE_API_KEY}`,
        },
        body: JSON.stringify({
          document_hash: data.hash,
          document_type: documentType,
          initial_data: initialData,
          is_internal_test: currentUser?.isAdmin,
          receiver: { spec: receiverSpec },
          sender: { spec: senderSpec },
          context: {
            source_partner: {
              id: targetRelationship.sender_partner.id,
              name: targetRelationship.sender_partner.name,
            },
            destination_partner: {
              id: targetRelationship.receiver_partner.id,
              name: targetRelationship.receiver_partner.name,
            },
            document_type: documentType,
            session_id: getSessionId(),
          },
        }),
      })
        .then(async (res) => {
          if (!res.ok) {
            const clone = res.clone();
            const body = await clone.text();
            throw new GenericError('WebEDI: Failed to extract document', body);
          }
          return res.json();
        })
        .then((content) => {
          onExtracted(content, data.hash);
          console.log(content);
        })
        .catch((e: Error) => {
          setExtractionError({
            title: t('webedi:documentExtractor.upload.error.title'),
            message: e.message,
          });
          logError(new GenericError(e.message, { error: e, data, initialData }));
          console.log(e);
        })
        .finally(() => {
          setIsExtracting(false);
        });
    },
    [
      track,
      initialData,
      currentUser?.isAdmin,
      receiverSpec,
      senderSpec,
      targetRelationship.sender_partner.id,
      targetRelationship.sender_partner.name,
      targetRelationship.receiver_partner.id,
      targetRelationship.receiver_partner.name,
      documentType,
      getSessionId,
      onExtracted,
      t,
    ],
  );

  return (
    <>
      <div className="mb-4 flex items-start justify-between">
        <div>
          <DialogTitle as="h3" className="text-lg font-medium text-gray-900">
            {t('webedi:documentExtractor.upload.title', {
              documentType: t(`common:messageTypes.singular.${documentType}`),
            })}
          </DialogTitle>
          <Description className="mt-2 text-sm leading-tight text-gray-500">
            <Translate
              i18nKey="webedi:documentExtractor.upload.subTitle"
              values={{ documentType: t(`common:messageTypes.singular.${documentType}`) }}
            />
          </Description>
        </div>
        <Button
          variant="minimal"
          iconOnly
          size="base"
          LeftIcon={XMarkIcon}
          onClick={close}
          analyticsId="document-extractor:close"
        />
      </div>
      <FileInput
        isProcessing={isExtracting}
        loadingLabel={<Translate i18nKey="webedi:documentExtractor.upload.loadingLabel" />}
        processingLabel={<Translate i18nKey="webedi:documentExtractor.upload.processingLabel" />}
        accept={{
          'application/pdf': ['.pdf'],
          'text/csv': ['.csv'],
          'application/vnd.ms-excel': ['.xls', '.xlsx'],
        }}
        hideSelectedFile
        onError={onError}
        onLoad={onLoadFile}
        loader={loader}
        dropzoneClassname="h-full"
      />
      {extractionError ? (
        <div className="mt-3 sm:mt-4">
          <Alert severity="error" fullWidth withoutIcon className="rounded-md">
            <AlertSection severity="error" title={extractionError?.title ?? ''}>
              {extractionError?.message}
            </AlertSection>
          </Alert>
        </div>
      ) : null}
    </>
  );
};
