import { ui } from '@procuros/datachecks';
import { useState, useEffect } from 'react';
import { applyRowMasksToData, getUiDetails } from 'services/datachecks/datachecks';
import { logError } from 'services/logging/logging';
import { ProcessSpecificationDTO } from 'support/types';

type DataChecksResult = {
  data: any;
  uiConfig: Array<ui.Section>;
  isLoading: boolean;
  promise?: Promise<{ data: any; uiConfig: Array<ui.Section> }>;
};

export const useFlattenDataAndUIConfig = (
  processSpecificationFields: ProcessSpecificationDTO['fields'] | undefined,
  canonical: ProcessSpecificationDTO['data'] | undefined,
): DataChecksResult => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataChecks, setDataChecks] = useState<any>({});

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);
    if (!processSpecificationFields || !canonical) {
      return;
    }

    try {
      getUiDetails({ fields: processSpecificationFields, data: canonical }).then((out) => {
        if (!mounted) return;
        setDataChecks({
          data: applyRowMasksToData(out.values, out.view),
          uiConfig: out.view?.map((section: any) => {
            if (section.type === 'line_items') {
              return {
                ...section,
                fields: section.fields.map((field: any) => {
                  if (field.id === 'transport_units') {
                    return {
                      ...field,
                      extraFields: {
                        disabledIfNested: true,
                      },
                    };
                  }
                  return field;
                }),
              };
            }
            return section;
          }),
        });
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
      logError(error);
    }

    return () => {
      mounted = false;
    };
  }, [canonical, processSpecificationFields]);

  return {
    uiConfig: dataChecks.uiConfig,
    data: dataChecks.data,
    isLoading,
  };
};
