import { ArrowUpTrayIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ui } from '@procuros/datachecks';
import { DataTable, useGenerateWebEDIColumns } from 'components/DataTable';
import { ColumnTypesInternal, DisplayModes } from 'components/DataTable/Types';
import { DataTableProvider, useDataTableContext } from 'components/DataTable/contexts/DataTableContext';
import { Button } from 'components/Form/Button';
import { ButtonType } from 'components/Form/Button/Button';
import { MenuButton, MenuButtonOption } from 'components/MenuButton/MenuButton';
import { useEmptyStructure } from 'hooks/useObjectStructure';
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nonNullable } from 'support/helpers/generic/generic';
import { DocumentType } from 'support/types';
import { DocumentSectionError } from '../DocumentSectionError/DocumentSectionError';
import { WebEDISection } from '../WebEDISection/WebEDISection';
import { usePinnedColumns } from '../helpers';
import { ImportMode, LineItemsImporter } from './components/LineItemsImporter';
import { useDocumentLineItemsConfig } from './hooks/useDocumentLineItemsConfig';
import { BATCHES_SUFFIX, scrollToBatch } from './helpers';

export const DocumentLineItems = memo(({ config, mode, documentType }: DocumentLineItemsProps) => {
  const { description, options, fields } = config;
  if (!fields?.length) return null;

  return (
    <DataTableProvider name={DATA_PREFIX}>
      <LineItemsTable
        mode={mode}
        description={description}
        config={fields}
        numberOfPinnedColumns={options?.pinned_column_count ?? 3}
        documentType={documentType}
      />
    </DataTableProvider>
  );
});

DocumentLineItems.displayName = 'DocumentLineItems';

type HeaderMenuProps = {
  dataPrefix: string;
  mode: DisplayModes;
  documentType: DocumentType;
  configuration: ui.LineItemsSection['fields'];
  setLineItemsImporterOpen: (value: boolean) => void;
  setImportMode: (value: ImportMode) => void;
};

type UploadButtonProps = {
  setImporterOpen: (value: boolean) => void;
  setImportMode: (value: ImportMode) => void;
  hasExistingItems: boolean;
  size?: ButtonType['size'];
};

const UploadButton = ({ setImporterOpen, setImportMode, hasExistingItems, size = 'base' }: UploadButtonProps) => {
  const { t } = useTranslation();
  if (hasExistingItems) {
    return (
      <MenuButton
        text={t('webedi:line_items.header_button.text_upload')}
        Icon={ArrowUpTrayIcon}
        variant="secondary"
        size={size}
        analyticsId="webedi:line_items_upload_button"
      >
        <MenuButtonOption
          onClick={() => {
            setImportMode('APPEND');
            setImporterOpen(true);
          }}
          className="border-b border-gray-100 last:border-b-0"
          analyticsId="webedi:line_items_upload_mode_append"
        >
          {t('webedi:line_items.header_button.upload_append.text')}
        </MenuButtonOption>
        <MenuButtonOption
          onClick={() => {
            setImportMode('REPLACE');
            setImporterOpen(true);
          }}
          className="border-b border-gray-100 last:border-b-0"
          analyticsId="webedi:line_items_upload_mode_replace"
        >
          {t('webedi:line_items.header_button.upload_replace.text')}
        </MenuButtonOption>
      </MenuButton>
    );
  }

  return (
    <Button
      variant="secondary"
      size={size}
      LeftIcon={ArrowUpTrayIcon}
      onClick={() => setImporterOpen(true)}
      analyticsId="webedi_line_items_upload"
    >
      {t('webedi:line_items.header_button.text_upload')}
    </Button>
  );
};

const HeaderMenu = ({
  dataPrefix,
  mode,
  documentType,
  configuration,
  setLineItemsImporterOpen,
  setImportMode,
}: HeaderMenuProps) => {
  const hasBatchColumns = configuration.some((field) => field.path.includes(BATCHES_SUFFIX));
  const { t } = useTranslation();
  const { fields, append, setValue } = useDataTableContext();
  const emptyLineItem = useEmptyStructure(fields);

  if (mode === DisplayModes.view) {
    return undefined;
  }

  if (documentType === DocumentType.productCatalog) {
    return (
      <UploadButton
        setImporterOpen={setLineItemsImporterOpen}
        setImportMode={setImportMode}
        hasExistingItems={!!fields?.length}
      />
    );
  }

  const actions = [
    {
      label: t('webedi:line_items.header_button.new_line_item.text'),
      onClick: () => {
        append({
          ...emptyLineItem,
          _internal: { parent_transport_unit: "$['transport_units'][0]", [ColumnTypesInternal.procuros_added]: true },
        });
        setTimeout(() => {
          const table = document.getElementById('line_items');
          table?.scrollTo({ top: table.scrollHeight });
        }, 1000);
      },
      analyticsId: 'webedi:line_item_header_add',
    },

    hasBatchColumns
      ? {
          label: t('webedi:line_items.header_button.new_batch.text'),
          onClick: () => {
            const batches = fields[0][BATCHES_SUFFIX];
            const newBatches = batches && Array.isArray(batches) ? batches.concat({}) : [{}];
            const numberOfBatches = newBatches.length;
            setValue(BATCHES_PATH, newBatches, { shouldDirty: true });

            setTimeout(() => {
              scrollToBatch(dataPrefix, numberOfBatches);
            }, 1000);
          },
          analyticsId: 'webedi:line_item_header_add_batch',
        }
      : null,
  ].filter(nonNullable);

  return (
    <MenuButton
      text={t('webedi:line_items.header_button.text')}
      Icon={PlusIcon}
      variant="secondary"
      size="base"
      analyticsId="webedi:line_item_header_add"
    >
      {actions.map((action) => (
        <MenuButtonOption
          key={action.label}
          onClick={action.onClick}
          className="border-b border-gray-100 last:border-b-0"
          analyticsId={action.analyticsId}
        >
          {action.label}
        </MenuButtonOption>
      ))}
    </MenuButton>
  );
};

type FooterMenuProps = {
  mode: DisplayModes;
  documentType: DocumentType;
  setLineItemsImporterOpen: (value: boolean) => void;
  setImportMode: (value: ImportMode) => void;
};
const FooterMenu = ({ mode, documentType, setLineItemsImporterOpen, setImportMode }: FooterMenuProps) => {
  const { t } = useTranslation();
  const { fields, append } = useDataTableContext();
  const emptyLineItem = useEmptyStructure(fields);

  if (mode === DisplayModes.view) {
    return undefined;
  }

  return (
    <div className="flex items-center space-x-3">
      <Button
        size="extra-small"
        variant="secondary"
        LeftIcon={PlusIcon}
        onClick={() => {
          append({
            ...emptyLineItem,
            _internal: { parent_transport_unit: "$['transport_units'][0]", [ColumnTypesInternal.procuros_added]: true },
          });
          setTimeout(() => {
            const table = document.getElementById('line_items');
            table?.scrollTo({ top: table.scrollHeight });
          }, 1000);
        }}
        analyticsId="webedi:line_items_add"
      >
        {t('webedi:line_items.add_button.text')}
      </Button>
      {documentType === DocumentType.productCatalog && (
        <>
          <span className="text-sm text-gray-500">{t('common:or')}</span>
          <UploadButton
            setImporterOpen={setLineItemsImporterOpen}
            setImportMode={setImportMode}
            hasExistingItems={!!fields?.length}
            size="extra-small"
          />
        </>
      )}
    </div>
  );
};

const LineItemsTable = ({
  mode,
  config,
  numberOfPinnedColumns,
  documentType,
  description,
}: {
  mode: DisplayModes;
  config: ui.LineItemsSection['fields'];
  numberOfPinnedColumns: number | undefined;
  documentType: DocumentType;
  description: ui.LineItemsSection['description'];
}) => {
  const { t } = useTranslation();
  const [lineItemsImporterOpen, setLineItemsImporterOpen] = useState(false);
  const [importMode, setImportMode] = useState<ImportMode>('APPEND');
  const { fields } = useDataTableContext();

  const { config: updatedConfig, deleteQuantityField } = useDocumentLineItemsConfig(config, mode, documentType);
  const tableConfig = useMemo(() => getTableConfig(mode, documentType), [mode, documentType]);
  const columns = useGenerateWebEDIColumns({
    config: updatedConfig,
    ...tableConfig,
    deleteQuantityField,
    data: fields,
    nestedLevelField: 'item.name',
  });

  const pinnedColumns = usePinnedColumns({
    config: updatedConfig,
    numberOfPinnedColumns,
    ...tableConfig,
  });

  return (
    <WebEDISection
      header={{
        title: t('webedi:line_items.section_title', { count: fields.length, numberOfLineItems: fields.length }),
        spacing: 'medium',
        description,
      }}
    >
      <div className="space-y-4">
        <DocumentSectionError path={DATA_PREFIX} />

        <LineItemsImporter isOpen={lineItemsImporterOpen} setOpen={setLineItemsImporterOpen} importMode={importMode} />
        <DataTable
          pinnedColumns={pinnedColumns}
          key={mode}
          id="line_items"
          meta={{ dataPrefix: DATA_PREFIX, mode }}
          columns={columns}
          data={fields}
          enableGlobalSearch={true}
          searchPositionStrategy="absolute"
          emptyState={
            mode === DisplayModes.create || mode === DisplayModes.edit
              ? t('webedi:line_items.empty_state.create.text')
              : t('webedi:line_items.empty_state.view.text')
          }
          headerMenu={
            <HeaderMenu
              dataPrefix={DATA_PREFIX}
              mode={mode}
              documentType={documentType}
              configuration={config}
              setLineItemsImporterOpen={setLineItemsImporterOpen}
              setImportMode={setImportMode}
            />
          }
          footerMenu={
            <FooterMenu
              mode={mode}
              documentType={documentType}
              setLineItemsImporterOpen={setLineItemsImporterOpen}
              setImportMode={setImportMode}
            />
          }
        />
      </div>
    </WebEDISection>
  );
};

const getTableConfig = (mode: DisplayModes, documentType: DocumentType) => {
  if (mode === DisplayModes.view) return {};
  return {
    selectableRows: true,
    deletableRows: documentType !== DocumentType.creditNote,
  };
};

type DocumentLineItemsProps = {
  config: ui.LineItemsSection;
  mode: DisplayModes;
  documentType: DocumentType;
};

const DATA_PREFIX = 'line_items';
const BATCHES_PATH = `${DATA_PREFIX}.0.${BATCHES_SUFFIX}`;
