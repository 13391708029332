import { Button } from 'components/Form/Button/Button';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  downloadAsCSV,
  downloadAsPDF,
  downloadReceiverEnvelope,
} from 'services/repositories/transactions/transactions';
import { routeToCreatePage } from 'support/helpers/navigation/navigation';
import { Connectors, DocumentType, MessageDTO, MessageV2DTO, ValueOf } from 'support/types';
import { AlreadyExistingMessageModal } from './AlreadyExistingMessageModal';
import { MenuButton, MenuButtonOption } from 'components/MenuButton/MenuButton';
import { AnalyticsProps } from 'support/helpers/analytics/analytics';
import { isMessageV2DTO } from 'support/helpers/transactions/transactions';
import { useCurrentPartner } from 'services/repositories/partners/partners';
import { useWebEDIStore } from 'stores/webedi/webedi';
import { useIsBetaOCR, useShouldShowOcr } from 'support/helpers/ocr/ocr';
import { Badge, BadgeColor } from 'components/Display/Badge/Badge';
import { useDocumentCreationRelationship } from 'support/helpers/relationships/relationships';
import { OCRSetupModal } from 'pages/Transactions/TransactionViewPage/OCRSetupModal/OCRSetupModal';

type ActionConfig = {
  label: string;
  test?: (message: MessageDTO | MessageV2DTO) => void;
  alternativeAction?: (message: MessageDTO | MessageV2DTO) => void;
  documentType?: DocumentType;
  testId?: string;
  secondaryOptionOnly?: boolean;
} & AnalyticsProps;

type DocumentTypeActionConfig = {
  documentType: DocumentType;
  actions: Array<ActionConfig>;
};

type GetActionsReturn = {
  primaryAction: DocumentAction | null;
  secondaryActions: Array<DocumentAction>;
};

type DocumentAction = {
  label: string;
  alternativeAction?: (message: MessageDTO | MessageV2DTO) => void;
  documentType?: DocumentType;
  testId?: string;
  documentTypeExists: boolean;
} & AnalyticsProps;

const mapOptionsToDropdown = (options: Array<DocumentAction>, t: TFunction) => {
  return options.map((option: DocumentAction) => ({
    label: t(option.label),
    value: option.label,
    testId: option.testId,
    analyticsId: option.analyticsId,
    analyticsProperties: option.analyticsProperties,
  }));
};

type ActionButtonsProps = {
  fromDocument: MessageDTO | MessageV2DTO;
  relatedTransactions: Array<MessageDTO | MessageV2DTO> | undefined;
  enabledDocumentTypes: Array<DocumentType>;
  fromDocumentType: ValueOf<DocumentType>;
};

export function ActionButtons({
  fromDocument,
  relatedTransactions,
  enabledDocumentTypes,
  fromDocumentType,
}: ActionButtonsProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedExistingDocumentType, setSelectedExistingDocumentType] = useState<DocumentType>();
  const { data: currentPartner } = useCurrentPartner();
  const destinationProcess = useWebEDIStore((state) => state.destinationProcess);

  // t('transactions:show.actionButtons.createOrderResponse')
  const createOrderResponseAction: ActionConfig = {
    label: 'transactions:show.actionButtons.createOrderResponse',
    documentType: DocumentType.orderResponse,
    analyticsId: 'transactions:create_order_response',
  };

  // t('transactions:show.actionButtons.createShippingNotice')
  const createShippingNoticeAction: ActionConfig = {
    label: 'transactions:show.actionButtons.createShippingNotice',
    documentType: DocumentType.shippingNotice,
    analyticsId: 'transactions:create_shipping_notice',
  };

  // t('transactions:show.actionButtons.createInvoice')
  const createInvoiceAction: ActionConfig = {
    label: 'transactions:show.actionButtons.createInvoice',
    documentType: DocumentType.invoice,
    analyticsId: 'transactions:create_invoice',
  };

  // t('transactions:show.actionButtons.createCreditNote')
  const createCreditNoteAction: ActionConfig = {
    label: 'transactions:show.actionButtons.createCreditNote',
    documentType: DocumentType.creditNote,
    analyticsId: 'transactions:create_credit_note',
  };

  // t('transactions:show.actionButtons.downloadAsCSV')
  const downloadAsCSVAction: ActionConfig = {
    label: 'transactions:show.actionButtons.downloadAsCSV',
    alternativeAction: (message: MessageDTO | MessageV2DTO) => downloadAsCSV(message),
    secondaryOptionOnly: true,
    analyticsId: 'transactions:download_as_csv',
  };

  // t('transactions:show.actionButtons.downloadAsPDF')
  const downloadAsPDFAction: ActionConfig = {
    label: 'transactions:show.actionButtons.downloadAsPDF',
    alternativeAction: (message: MessageDTO | MessageV2DTO) => downloadAsPDF(message),
    secondaryOptionOnly: true,
    analyticsId: 'transactions:download_as_pdf',
  };

  // t('transactions:show.actionButtons.downloadReceiverEnvelopeRawPayload_EDIFACT')
  // t('transactions:show.actionButtons.downloadReceiverEnvelopeRawPayload_EDIFACT_V2')
  // t('transactions:show.actionButtons.downloadReceiverEnvelopeRawPayload_PHBEST')
  // t('transactions:show.actionButtons.downloadReceiverEnvelopeRawPayload_FIXED')
  // t('transactions:show.actionButtons.downloadReceiverEnvelopeRawPayload_TRADACOMS')
  // t('transactions:show.actionButtons.downloadReceiverEnvelopeRawPayload_XML')
  // t('transactions:show.actionButtons.downloadReceiverEnvelopeRawPayload_CSV')
  // t('transactions:show.actionButtons.downloadReceiverEnvelopeRawPayload_NONE')
  const downloadAsReceiverEnvelopeRawPayload: ActionConfig = {
    label:
      'transactions:show.actionButtons.downloadReceiverEnvelopeRawPayload' +
      (destinationProcess?.configuration?.normalizer ? `_${destinationProcess?.configuration?.normalizer}` : ''),
    alternativeAction: (message: MessageDTO | MessageV2DTO) => {
      const receiverEnvelope = isMessageV2DTO(message) ? message.receiver_envelope : message.receiverEnvelope;

      if (!receiverEnvelope) {
        return;
      }

      return downloadReceiverEnvelope(receiverEnvelope.id);
    },
    secondaryOptionOnly: true,
    analyticsId: 'transactions:download_receiver_envelope_raw_payload',
    test: (message: MessageDTO | MessageV2DTO) => {
      const isUsingMessageV2DTO = isMessageV2DTO(message);
      const receiverEnvelope = isUsingMessageV2DTO ? message?.receiver_envelope : message.receiverEnvelope;

      if (!receiverEnvelope) {
        return false;
      }

      if (receiverEnvelope.receiverIntegration.connector === Connectors.WEB_EDI) {
        return false;
      }

      const receiverPartner = isUsingMessageV2DTO ? message?.receiver_partner : message.receiver;

      return receiverPartner?.id === currentPartner?.id;
    },
  };

  const orderActions: DocumentTypeActionConfig = {
    documentType: DocumentType.order,
    actions: [
      createOrderResponseAction,
      createShippingNoticeAction,
      createInvoiceAction,
      downloadAsPDFAction,
      downloadAsCSVAction,
    ],
  };

  const orderResponseActions: DocumentTypeActionConfig = {
    documentType: DocumentType.orderResponse,
    actions: [createShippingNoticeAction, createInvoiceAction],
  };

  const shippingNoticeActions: DocumentTypeActionConfig = {
    documentType: DocumentType.shippingNotice,
    actions: [createInvoiceAction, downloadAsPDFAction],
  };

  const invoiceActions: DocumentTypeActionConfig = {
    documentType: DocumentType.invoice,
    actions: [createCreditNoteAction, downloadAsPDFAction],
  };

  const creditNoteActions: DocumentTypeActionConfig = {
    documentType: DocumentType.creditNote,
    actions: [],
  };

  const documentActions: Array<DocumentTypeActionConfig> = [
    orderActions,
    orderResponseActions,
    shippingNoticeActions,
    invoiceActions,
    creditNoteActions,
  ];

  const allDocumentsActions = [downloadAsReceiverEnvelopeRawPayload];
  const actions: Array<ActionConfig> =
    documentActions
      .find((action: DocumentTypeActionConfig) => action.documentType === fromDocumentType)
      ?.actions?.concat(allDocumentsActions) ?? allDocumentsActions;

  const availableActions = actions
    // Only actions for enabled document types or without a document type
    .filter(
      (action: ActionConfig) =>
        typeof action.documentType === 'undefined' || enabledDocumentTypes.includes(action.documentType),
    )
    // Only actions that pass their test or without a test
    .filter((action: ActionConfig) => !action.test || action.test(fromDocument))
    // Add if document already exists
    .map((action: ActionConfig) => ({
      ...action,
      documentTypeExists: Boolean(
        relatedTransactions?.some((transaction: MessageDTO | MessageV2DTO) => transaction.type === action.documentType),
      ),
    }));

  const { primaryAction, secondaryActions } = availableActions.reduce(
    (acc: GetActionsReturn, action) => {
      if (!acc.primaryAction && !action.secondaryOptionOnly && !action.documentTypeExists) {
        return {
          ...acc,
          primaryAction: action,
        };
      }

      return {
        ...acc,
        secondaryActions: [...acc.secondaryActions, action],
      };
    },
    { primaryAction: null, secondaryActions: [] },
  );

  const shouldShowOCR = useShouldShowOcr(primaryAction?.documentType);
  const shippingNoticeTargetRelationship = useDocumentCreationRelationship({
    relationshipId: undefined,
    sourceMessageId: fromDocument.id,
    documentType: primaryAction?.documentType,
  });

  const { isLoading, isBeta: isBetaOCR } = useIsBetaOCR({
    targetRelationshipId: shippingNoticeTargetRelationship?.id,
    sourceMessageId: fromDocument.id,
  });

  const navigateToForm = (documentType: DocumentType, showUploadModal?: boolean) => {
    navigate(routeToCreatePage(fromDocument.id, documentType, showUploadModal));
  };

  const executeOption = (actionConfig?: DocumentAction) => {
    if (!actionConfig) {
      return;
    }

    const { alternativeAction, documentType, documentTypeExists } = actionConfig;

    if (alternativeAction) {
      alternativeAction(fromDocument);
      return;
    }

    if (!documentType) {
      return;
    }

    if (documentTypeExists) {
      setSelectedExistingDocumentType(documentType);
      return;
    }

    navigateToForm(documentType);
  };

  const hideModal = () => {
    setSelectedExistingDocumentType(undefined);
  };

  const confirmCreateAgain = () => {
    hideModal();
    navigateToForm(selectedExistingDocumentType!);
  };

  const [isSetupOCRModalOpen, setIsSetupOCRModalOpen] = useState(false);
  const openSetupOCRModal = () => {
    setIsSetupOCRModalOpen(true);
  };

  const primaryButton = primaryAction ? (
    shouldShowOCR ? (
      <MenuButton
        className="ml-2"
        variant="primary"
        text={t(primaryAction.label)}
        size="base"
        analyticsId="transactions:primary_action_ocr"
      >
        <MenuButtonOption
          key="manually"
          onClick={() => {
            navigateToForm(primaryAction.documentType!);
          }}
          analyticsId={primaryAction.analyticsId}
          analyticsProperties={primaryAction.analyticsProperties}
          className="max-w-72"
        >
          {t('transactions:show.actionButtons.createManually')}
        </MenuButtonOption>
        <MenuButtonOption
          key="upload"
          onClick={isBetaOCR ? openSetupOCRModal : () => navigateToForm(primaryAction.documentType!, true)}
          analyticsId={`${primaryAction.analyticsId}_upload`}
          analyticsProperties={primaryAction.analyticsProperties}
          className="max-w-72"
        >
          <span className="space-y-2">
            <span className="flex justify-between gap-2">
              {t('transactions:show.actionButtons.uploadDocument.label')}
              {isBetaOCR && !isLoading ? (
                <Badge color={BadgeColor.purple}>{t('transactions:show.actionButtons.uploadDocument.badge')}</Badge>
              ) : null}
            </span>
            {isBetaOCR && !isLoading ? (
              <span className="block text-sm font-normal text-gray-500">
                {t('transactions:show.actionButtons.uploadDocument.description', {
                  documentType: t(`common:messageTypes.plural.${primaryAction.documentType}`),
                })}
              </span>
            ) : null}
          </span>
        </MenuButtonOption>
      </MenuButton>
    ) : (
      <Button
        className="ml-2"
        onClick={() => executeOption(primaryAction)}
        testId="ActionButtonPrimaryAction"
        analyticsId="transactions:primary_action"
      >
        {t(primaryAction.label)}
      </Button>
    )
  ) : null;

  return (
    <div className="flex flex-row items-center">
      {!!secondaryActions.length && (
        <MenuButton
          variant="secondary"
          text={t('transactions:show.actionButtons.moreOptions')}
          testId="ActionButtonOptions"
          size="base"
          analyticsId="transactions:secondary_actions"
        >
          {mapOptionsToDropdown(secondaryActions, t).map((action, i) => {
            return (
              <MenuButtonOption
                data-testid={action.testId}
                key={action.analyticsId}
                onClick={() => {
                  executeOption(secondaryActions[i]);
                }}
                analyticsId={action.analyticsId}
                analyticsProperties={action.analyticsProperties}
              >
                {action.label}
              </MenuButtonOption>
            );
          })}
        </MenuButton>
      )}
      {primaryButton}
      <OCRSetupModal
        setOpen={setIsSetupOCRModalOpen}
        open={isSetupOCRModalOpen}
        sourceMessageId={fromDocument.id}
        documentType={primaryAction?.documentType}
      />
      <AlreadyExistingMessageModal
        isOpen={!!selectedExistingDocumentType}
        onAction={confirmCreateAgain}
        onClose={hideModal}
      />
    </div>
  );
}
