import { Popup } from 'components/Display/Popup/Popup';
import { Button } from 'components/Form/Button';
import { Translate } from 'components/Translate/Translate';
import { FC } from 'react';
import { DocumentType, PartnerV2 } from 'support/types';
import { useDocumentCurrency } from 'components/WebEDI/helpers';
import { useInvoiceAndCreditNoteSummary } from 'components/WebEDI/hooks/useInvoiceAndCreditNoteSummary';
import { useOrderResponseSummary } from 'components/WebEDI/hooks/useOrderResponseSummary';
import { usePriceCatalogSummary } from 'components/WebEDI/hooks/usePriceCatalogSummary';
import { useShippingNoticeSummary } from 'components/WebEDI/hooks/useShippingNoticeSummary';
import { formatCurrency } from 'support/helpers/currency/currency';
import { useTranslation } from 'react-i18next';

type SendConfirmationModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  documentType: DocumentType;
  receiverTradePartner: PartnerV2 | undefined;
};
export const SendConfirmationModal: FC<SendConfirmationModalProps> = ({
  onConfirm,
  onCancel,
  open,
  documentType,
  receiverTradePartner,
}) => {
  const { t } = useTranslation();
  const primaryButtonText = useConfirmationButtonText({ documentType });

  const CancelButton = () => (
    <Button analyticsId="webedi:cancelSendDialog" onClick={onCancel} variant="secondary">
      <Translate i18nKey="common:cancel" />
    </Button>
  );

  const PrimaryButton = () => (
    <Button analyticsId="webedi:confirmSendDialog" onClick={onConfirm}>
      {primaryButtonText}
    </Button>
  );

  const documentTypeTranslation = t(`common:messageTypes.singular.${documentType}`);
  return (
    <Popup
      CancelButton={CancelButton}
      PrimaryButton={PrimaryButton}
      open={open}
      onClose={onCancel}
      title={
        <Translate i18nKey="webedi:sendDocumentConfirmation.title" values={{ documentType: documentTypeTranslation }} />
      }
      subtitle={
        <Translate
          i18nKey="webedi:sendDocumentConfirmation.description"
          values={{ documentType: documentTypeTranslation, receiverTradePartner: receiverTradePartner?.name }}
        />
      }
    ></Popup>
  );
};

const useConfirmationButtonText = ({ documentType }: { documentType: DocumentType }) => {
  const { t } = useTranslation();
  const orderResponseSummary = useOrderResponseSummary();
  const shippingNoticeSummary = useShippingNoticeSummary();
  const invoiceAndCreditNoteSummary = useInvoiceAndCreditNoteSummary();
  const priceCatalogSummary = usePriceCatalogSummary();
  const currency = useDocumentCurrency();

  switch (documentType) {
    case DocumentType.shippingNotice:
      return t('webedi:sendDocumentConfirmation.confirmButton.shippingNotice', {
        count: Math.round(shippingNoticeSummary.totalQuantity),
      });
    case DocumentType.invoice:
      return t('webedi:sendDocumentConfirmation.confirmButton.invoice', {
        value: formatCurrency(invoiceAndCreditNoteSummary.due, currency),
      });
    case DocumentType.creditNote:
      return t('webedi:sendDocumentConfirmation.confirmButton.creditNote', {
        value: formatCurrency(invoiceAndCreditNoteSummary.due, currency),
      });
    case DocumentType.orderResponse:
      return t('webedi:sendDocumentConfirmation.confirmButton.orderResponse', {
        count: Math.round(orderResponseSummary.totalQuantity),
      });
    case DocumentType.productCatalog:
      return t('webedi:sendDocumentConfirmation.confirmButton.productCatalog', {
        count: priceCatalogSummary.lineCount,
      });
  }

  return t('webedi:sendDocumentConfirmation.confirmButton.default');
};
