import { DocumentType, ProcessSpecificationDTO } from 'support/types';

// This is a temporary solution to filter out line items fields for a specific partner
const partnersToFilterOut = [
  '9da4ab96-0e9f-44a3-b32f-da2e59db1fd5', // MOU | ARTCRAFTS INTERNATIONAL SPA
  '9deb3010-64ed-4fe3-bef7-efd0c72fcb5a', // Allude
];

export const _tempSlowDatachecks = {
  maybeFilterLineItemsFields: (
    fields: ProcessSpecificationDTO['fields'] | undefined,
    senderPartnerId: string | undefined,
    documentType: DocumentType | undefined,
  ) => {
    if (
      !!senderPartnerId &&
      partnersToFilterOut.includes(senderPartnerId) &&
      documentType === DocumentType.productCatalog
    ) {
      return fields?.filter((field) => !field.path.startsWith('line_items'));
    }
    return fields;
  },
  maybeRemoveLineItemsData: (
    data: any,
    senderPartnerId: string | undefined,
    documentType: DocumentType | undefined,
  ) => {
    if (
      !!senderPartnerId &&
      partnersToFilterOut.includes(senderPartnerId) &&
      documentType === DocumentType.productCatalog
    ) {
      const { line_items, ...rest } = data;
      return rest;
    }
    return data;
  },
};
